import React, { useEffect, useState } from "react";
import { Modal, Progress, Tabs, Skeleton } from 'antd';
import AssetComponent from "./asset";
import LoansComponent from "./loans";
import StakingComponent from "./staking";
import HistoryComponent from "./history";
import { useAccount } from "wagmi";
import UserContractMapping from "./utils/UserContractMapping.json"
import abiPto from "./utils/abiPto.json"
import "./style.scss"
import "../p2p-lending/style.scss"
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import EmptyConnect from "../../../components/empty-connect";
import { AppHome } from "../../../api/appHome";
import { useContract } from "../../../hooks/useContract";
import ChartJs from "./chartJs";
import abi_StakingTierManager from "./rank/utils/abi_StakingTierManager.json"

const Portfolio = () => {
    const { address } = useAccount();
    const [dataContract, setdataContract]: any = useState()
    const [dataInfo, setDataInfo]: any = useState()
    const [isModalOpen, setIsModalOpen] = useState(false);
    let scContract = dataContract && dataContract?.p2p_sum_transaction_contract
    let contractProfile: any = useContract(scContract, UserContractMapping)
    const [userContractCount, setUserContractCount]: any = useState()
    const [arrParam, setArrParam] = useState([])
    const [arrParamPto, setArrParamPto] = useState([])
    const [dataLoans, setDataLoans] = useState(undefined)
    const [countMappingPto, setCountMappingPto]: any = useState()
    const [loadingLoans, setLoadingLoans] = useState(false)
    const [dataAsset, setDataAsset] = useState(undefined)
    const [loadingAsset, setLoadingAsset] = useState(false)
    const [infoTierId, setInfoTierId]: any = useState()
    const [infoTierName, setInfoTierName]: any = useState()
    let scContractTier: any = dataContract && dataContract?.stake_tier_manager_contract
    const contractReadTier: any = useContract(scContractTier, abi_StakingTierManager)
    let scContractPto: any = dataContract && dataContract?.pto_sum_transaction_contract
    const contractReadPto: any = useContract(scContractPto, abiPto)



    // comment


    const onChangeTabs = async (key: any) => {
        console.log('key', key)
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const getTierByUser = async () => {
        if (contractReadTier && address) {
            let tier = await contractReadTier?.getTierFromStaking(address)
            console.log('tier', tier)
            setInfoTierId(Number(tier?.tierId))
            setInfoTierName(tier?.tierName)
        }
    }

    useEffect(() => {
        if (address && contractReadTier) {
            getTierByUser()
        }
    }, [address, scContractTier, infoTierName, contractReadTier]);

    const getUserContract = async () => {
        if (contractProfile && address) {
            let userContract = await contractProfile.getUserContractCount(address)
            setUserContractCount(Number(userContract))
        }
    }

    useEffect(() => {
        getUserContract()
    }, [address, contractProfile]);

    const getHistoryByUser = async () => {
        if (contractProfile && address && userContractCount) {
            let loan = await contractProfile.getUserContracts(address);
            setArrParam(loan);
        }
    };

    useEffect(() => {
        if (address) {
            getHistoryByUser()
        }
    }, [address, userContractCount]);

    const getInfoP2pLoans = async () => {
        setLoadingLoans(true)
        let params = {
            contracts: arrParam,
            pageSize: 6,
            pageIndex: 0,
        }
        try {
            let res = await AppHome.getP2pYourLoans(
                params
            );
            if (res.data.success === true) {
                setDataLoans(res.data.data);
                setLoadingLoans(false)
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        getInfoP2pLoans()
    }, [arrParam]);

    const getContractPto = async () => {
        if (contractReadPto && address && scContractPto) {
            let userMapping = await contractReadPto?.getUserContractCount(address)
            setCountMappingPto(Number(userMapping))
        }
    }

    useEffect(() => {
        getContractPto()
    }, [contractReadPto, scContractPto]);

    const getArrPtoByUser = async () => {
        if (contractReadPto && address && countMappingPto) {
            let arr = await contractReadPto.getUserContracts(address);
            setArrParamPto(arr)
        }
    };

    useEffect(() => {
        if (address) {
            getArrPtoByUser()
        }
    }, [address, countMappingPto]);


    const getTokenizeAsset = async () => {
        setLoadingAsset(true)
        let params = {
            pageSize: 6,
            pageIndex: 0,
            contracts: arrParamPto
        }
        try {
            let res = await AppHome.getTokenizeAsset(
                params
            );
            if (res.data.success === true) {
                setDataAsset(res.data.data);
                setLoadingAsset(false)
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        getTokenizeAsset()
    }, [arrParamPto]);

    const tabAsset = (
        <>
            <div className="title-tabs">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.66683 4.78711C1.93045 4.78711 1.3335 5.38406 1.3335 6.12044V7.22362C1.3335 7.81243 1.71972 8.33153 2.2837 8.50072L7.23391 9.98577C7.73374 10.1357 8.26659 10.1357 8.76642 9.98577L13.7166 8.50072C14.2806 8.33153 14.6668 7.81243 14.6668 7.22362V6.12044C14.6668 5.38406 14.0699 4.78711 13.3335 4.78711H2.66683ZM8.5 7.07422C8.5 6.79808 8.27614 6.57422 8 6.57422C7.72386 6.57422 7.5 6.79808 7.5 7.07422V8.21707C7.5 8.49321 7.72386 8.71707 8 8.71707C8.27614 8.71707 8.5 8.49321 8.5 8.21707V7.07422Z" fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.38916 9.17621V11.8338C1.38916 13.5827 2.80693 15.0005 4.55583 15.0005H11.4447C13.1936 15.0005 14.6114 13.5827 14.6114 11.8338V9.17606C14.4269 9.29681 14.2231 9.39274 14.004 9.45848L9.05377 10.9435C8.36649 11.1497 7.63383 11.1497 6.94656 10.9435L1.99635 9.45848C1.7773 9.39276 1.57358 9.29689 1.38916 9.17621ZM1.42995 7.72168L8.00027 8.83391L14.5704 7.72172C14.4221 8.08948 14.1133 8.38164 13.7166 8.50065L8.76642 9.9857C8.26659 10.1357 7.73374 10.1357 7.23391 9.9857L2.2837 8.50065C1.88697 8.38163 1.57819 8.08946 1.42995 7.72168ZM7.99983 9.83383L8.00021 9.8339L8.00016 9.83391L7.99983 9.83383Z" fill="white" />
                    <path d="M10.2223 4.7857V3.83333C10.2223 3.09695 9.62532 2.5 8.88894 2.5H7.11117C6.37479 2.5 5.77783 3.09695 5.77783 3.83333L5.77783 4.7857" stroke="white" />
                </svg>

                Your Assets
            </div>
        </>
    )

    const tabLoan = (
        <>
            <div className="title-tabs">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.4">
                        <path d="M7.49987 3.10039V9.11373C7.49987 9.33373 7.39321 9.53373 7.21321 9.66039L2.40654 13.0271C2.01321 13.3004 1.45321 13.0871 1.36654 12.6137C1.26654 12.0404 1.39987 11.3671 1.79987 10.6404L3.87987 6.90039L5.83987 3.37373C5.99321 3.10039 6.15987 2.86039 6.33321 2.65373C6.73321 2.18706 7.49987 2.48706 7.49987 3.10039Z" fill="white" />
                        <path d="M13.5931 13.0271L8.78642 9.66039C8.60642 9.53373 8.49976 9.33373 8.49976 9.11373V3.10039C8.49976 2.48706 9.26642 2.18706 9.66642 2.65373C9.83976 2.86039 10.0064 3.10039 10.1598 3.37373L12.1198 6.90039L14.1998 10.6404C14.5998 11.3671 14.7331 12.0404 14.6331 12.6137C14.5464 13.0871 13.9864 13.3004 13.5931 13.0271Z" fill="white" />
                        <path d="M3.50638 13.2603L7.60638 10.527C7.82638 10.3803 8.18638 10.3803 8.40638 10.527L12.5064 13.2603C13.593 13.987 13.413 14.5803 12.1064 14.5803H3.89971C2.59971 14.5737 2.41971 13.9803 3.50638 13.2603Z" fill="white" />
                    </g>
                </svg>

                Your Loans
            </div>
        </>
    )

    const tabStaking = (
        <>
            <div className="title-tabs">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.4">
                        <path d="M10.7933 1.83301H5.20658C3.06659 1.83301 1.33325 3.56634 1.33325 5.70634V7.33301C1.33325 7.69967 1.63325 7.99967 1.99992 7.99967H13.9999C14.3666 7.99967 14.6666 7.69967 14.6666 7.33301V5.70634C14.6666 3.56634 12.9333 1.83301 10.7933 1.83301ZM4.49992 5.99967C4.49992 6.27301 4.27325 6.49967 3.99992 6.49967C3.72659 6.49967 3.49992 6.27301 3.49992 5.99967V4.66634C3.49992 4.39301 3.72659 4.16634 3.99992 4.16634C4.27325 4.16634 4.49992 4.39301 4.49992 4.66634V5.99967ZM7.16658 5.99967C7.16658 6.27301 6.93992 6.49967 6.66658 6.49967C6.39325 6.49967 6.16658 6.27301 6.16658 5.99967V4.66634C6.16658 4.39301 6.39325 4.16634 6.66658 4.16634C6.93992 4.16634 7.16658 4.39301 7.16658 4.66634V5.99967ZM11.9999 5.83301H9.33325C9.05992 5.83301 8.83325 5.60634 8.83325 5.33301C8.83325 5.05967 9.05992 4.83301 9.33325 4.83301H11.9999C12.2733 4.83301 12.4999 5.05967 12.4999 5.33301C12.4999 5.60634 12.2733 5.83301 11.9999 5.83301Z" fill="white" />
                        <path d="M1.33325 11.2933C1.33325 13.4333 3.06659 15.1667 5.20658 15.1667H10.7866C12.9333 15.1667 14.6666 13.4333 14.6666 11.2933V9.66667C14.6666 9.3 14.3666 9 13.9999 9H1.99992C1.63325 9 1.33325 9.3 1.33325 9.66667V11.2933ZM9.33325 11.3333H11.9999C12.2733 11.3333 12.4999 11.56 12.4999 11.8333C12.4999 12.1067 12.2733 12.3333 11.9999 12.3333H9.33325C9.05992 12.3333 8.83325 12.1067 8.83325 11.8333C8.83325 11.56 9.05992 11.3333 9.33325 11.3333ZM6.16658 11.1667C6.16658 10.8933 6.39325 10.6667 6.66658 10.6667C6.93992 10.6667 7.16658 10.8933 7.16658 11.1667V12.5C7.16658 12.7733 6.93992 13 6.66658 13C6.39325 13 6.16658 12.7733 6.16658 12.5V11.1667ZM3.49992 11.1667C3.49992 10.8933 3.72659 10.6667 3.99992 10.6667C4.27325 10.6667 4.49992 10.8933 4.49992 11.1667V12.5C4.49992 12.7733 4.27325 13 3.99992 13C3.72659 13 3.49992 12.7733 3.49992 12.5V11.1667Z" fill="white" />
                    </g>
                </svg>

                Your Staking
            </div>
        </>
    )

    const tabHistory = (
        <>
            <div className="title-tabs">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.833252 5.16732C0.833252 3.05023 2.54949 1.33398 4.66658 1.33398H11.3333C13.4503 1.33398 15.1666 3.05023 15.1666 5.16732V11.834C15.1666 13.9511 13.4503 15.6673 11.3333 15.6673H4.66658C2.54949 15.6673 0.833252 13.9511 0.833252 11.834V5.16732ZM6.25064 11.4453C6.34676 11.5895 6.51069 11.6736 6.68388 11.6677C6.85706 11.6617 7.01481 11.5665 7.10079 11.416L9.30393 7.56054L10.2195 9.39158C10.3041 9.56097 10.4773 9.66797 10.6667 9.66797H12C12.2761 9.66797 12.5 9.44411 12.5 9.16797C12.5 8.89183 12.2761 8.66797 12 8.66797H10.9757L9.78055 6.2777C9.69808 6.11277 9.53153 6.00661 9.34721 6.00149C9.16288 5.99638 8.9907 6.09313 8.89921 6.25323L6.63393 10.2175L5.74936 8.89062C5.65663 8.75152 5.50051 8.66797 5.33333 8.66797H4C3.72386 8.66797 3.5 8.89183 3.5 9.16797C3.5 9.44411 3.72386 9.66797 4 9.66797H5.06574L6.25064 11.4453Z" fill="white" fill-opacity="0.4" />
                </svg>

                History
            </div>
        </>
    )

    const AccountInfo = async () => {
        try {
            let res = await AppHome.AccountInfo(
                address
            );
            if (res.data.success === true) {

                setDataInfo(res.data.data);
                localStorage.setItem('userAccount', JSON.stringify(res.data.data.dataAccount));
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        if (address) {

            AccountInfo()
        }
    }, [address]);

    const [activeIndex, setActiveIndex]: any = useState(dataInfo?.tier?.tier);

    useEffect(() => {
        setActiveIndex(dataInfo?.tier?.tier)
    }, [dataInfo]);

    const SettingContract = async () => {
        try {
            let res = await AppHome.getSettingContract();
            if (res.data.success === true) {
                setdataContract(res.data.data);
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        SettingContract()
    }, []);

    const images = [
        "/images/user/standard.gif",
        "/images/user/dolphin.gif",
        "/images/user/shark.gif",
        "/images/user/whale.gif",
        "/images/user/SVIP.gif"
    ];

    const imagesRender = [
        "/images/user/ti-1.png",
        "/images/user/ti-2.png",
        "/images/user/ti-3.png",
        "/images/user/ti-4.png",
        "/images/user/ti-5.png"
    ];

    const handleNext = () => {
        setActiveIndex((prevIndex: any) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handlePrev = () => {
        setActiveIndex((prevIndex: any) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    const renderTierName = (index: any) => {
        switch (index) {
            case 0:
                return "Standard"
            case 1:
                return "Dolphin"
            case 2:
                return "Shark"
            case 3:
                return "Whale"
            case 4:
                return "SVIP"
        }
    }

    const userNameCut = dataInfo?.dataAccount?.firstName.length > 10
        ? `${dataInfo.dataAccount.firstName.slice(0, 4)}...${dataInfo.dataAccount.firstName.slice(-4)}`
        : dataInfo?.dataAccount?.firstName;

    return (
        <>
            <div className="section-grow mar-b-100-p2p position-relative">
                {address === null || !address ?
                    <>
                        <EmptyConnect />
                    </>
                    :
                    <>
                        {/* <KycPage /> */}
                        <div className="grow-wrap wrap-mobile-porfolio">
                            <div className="wrap-left">
                                <div className="profit-user">
                                    <div className="content-left">
                                        <div className="user-create">
                                            {dataInfo === undefined || infoTierName === undefined || infoTierId === undefined ?
                                                <>
                                                    <Skeleton paragraph={{ rows: 10 }} />
                                                </>
                                                :
                                                <>
                                                    <div className="box-img-edit">
                                                        <Link to="/apps/edit-profile">
                                                            <img src="/images/user/edit.png" alt="" />
                                                        </Link>
                                                    </div>
                                                    <div className="box-img">
                                                        <img src={dataInfo?.dataAccount?.avatar} alt="" />
                                                    </div>
                                                    <div className="name-user">
                                                        {userNameCut} {dataInfo?.dataAccount?.isKYC ? <img src="/images/lending/very.png" alt="" /> : <img src="/images/lending/very-false.png" alt="" />}
                                                    </div>
                                                    {dataInfo?.dataAccount?.isPremium ? (
                                                        <div className="button-vesting-user">
                                                            <Link to="/apps/vesting">
                                                                <button className="btn-vesting-user">
                                                                    Claim Vesting
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    ) : ("")}
                                                    <div className="details-user">
                                                        <div className="txt">
                                                            Details
                                                        </div>
                                                        <ul className="list-repage">
                                                            <li>
                                                                <div className="l">
                                                                    KYC Status
                                                                </div>
                                                                <div className="r">
                                                                    {dataInfo?.dataAccount?.isKYC ? <span className="veryfi-span">Verified</span> : <span className="unveryfi-span">Unverified</span>}
                                                                </div>
                                                            </li>
                                                            <li onClick={showModal}>
                                                                <Link to="/apps/rank">
                                                                    <div className="box-tier-remask">
                                                                        <div className="l">
                                                                            Tier
                                                                        </div>
                                                                        <div className="r">
                                                                            <div className="tier-images">
                                                                                <img src={imagesRender[infoTierId]} /> {infoTierName}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="verify-account-email">
                                                        <div className="text">
                                                            <Link to="/apps/verify-email">
                                                                Email verification check
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="content-right">
                                        {dataInfo === undefined ?
                                            <>
                                                <div className="box-chart res">
                                                    <Skeleton paragraph={{ rows: 10 }} />
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="box-chart">
                                                    <ChartJs />
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="list-tabs-user">
                                    <div className="tabs-list">
                                        <Tabs defaultActiveKey="1" onChange={onChangeTabs}>
                                            <Tabs.TabPane tab={tabAsset} key="1">
                                                <AssetComponent loadingAsset={loadingAsset} dataAsset={dataAsset} />
                                            </Tabs.TabPane>
                                            <Tabs.TabPane tab={tabLoan} key="2">
                                                <LoansComponent loadingLoans={loadingLoans} dataLoans={dataLoans} />
                                            </Tabs.TabPane>
                                            <Tabs.TabPane tab={tabStaking} key="3">
                                                <StakingComponent />
                                            </Tabs.TabPane>
                                            <Tabs.TabPane tab={tabHistory} key="4">
                                                <HistoryComponent />
                                            </Tabs.TabPane>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                            <div className="wrap-right">
                                <div className="guide-user-pro">
                                    <div className="my-profile-user">
                                        <div className="title">
                                            My Portfolio
                                        </div>
                                        {dataInfo === undefined ?
                                            <>
                                                <Skeleton paragraph={{ rows: 10 }} />
                                            </>
                                            :
                                            <>
                                                <div className="box-account">
                                                    <div className="top-account">
                                                        Account ({address?.slice(0, 4)}...{address?.slice(-4)})
                                                        <CopyToClipboard
                                                            text={`${address}`}
                                                            onCopy={() => {
                                                                toast.success('Copied success', {
                                                                    position: "top-right",
                                                                    autoClose: 2000,
                                                                    hideProgressBar: false,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: true,
                                                                    progress: undefined,
                                                                    theme: "light",

                                                                });
                                                            }}
                                                        >
                                                            <img src="/images/user/copy-row.png" alt="" />
                                                        </CopyToClipboard>
                                                    </div>
                                                    <div className="bottom-account">
                                                        <div className="balance">
                                                            {Number(dataInfo?.nativeBalance?.balance).toFixed(6)} ETH <span>~${Number(dataInfo?.nativeBalance?.priceUsdt).toFixed(4)}</span>
                                                        </div>
                                                        <div className="box-img">
                                                            <img src="/images/user/logo-pro.png" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="title">
                                                    My Tokens
                                                </div>
                                                <div className="list-token-by-user">
                                                    <div className="item-top">
                                                        <div className="txt">
                                                            Type
                                                        </div>
                                                        <div className="txt">
                                                            Balance
                                                        </div>
                                                    </div>
                                                    <div className="item-bottom">
                                                        {dataInfo?.token.length === 0 ?
                                                            <>
                                                                <div className="box-no-data-lending">
                                                                    <img className='img-empty' src="/images/empty1.png" alt="" />
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                {dataInfo && dataInfo?.token?.map((item: any, index: any) => (
                                                                    <>
                                                                        <div className="token-list">
                                                                            <div className="name-token">
                                                                                <div className="logo-token">
                                                                                    <div className="quote-symbol">
                                                                                        <img src={item?.logo ? item?.logo : "/images/user/base-de.png"} className="big" alt="" />
                                                                                        <img src="/images/user/base.png" className="small" alt="" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="symbol">
                                                                                    {item?.symbol}
                                                                                    <div className="balance">
                                                                                        ${Number(item?.price).toFixed(5)} {item?.percentChange < 0 ? <span className="red">{Number(item?.percentChange).toFixed(2)}%</span> : <span className="green">{Number(item?.percentChange).toFixed(2)}%</span>}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="balance-token">
                                                                                <div className="price">
                                                                                    {new Intl.NumberFormat('en-US').format(item?.balance)} {item?.symbol} <span>~${new Intl.NumberFormat('en-US').format(item?.priceUsdt)}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))}
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            <Modal className="modal-purchase" title="Tier Information" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className="tier-infomation-row">
                    <div className="infomation-top">
                        <div className="images-tier-top">
                            <div className="box-img">
                                <div className="left-arrow" onClick={handlePrev}>
                                    <img src="/images/user/tier-left.png" alt="Previous" />
                                </div>
                                <img src={images[activeIndex]} alt="Active" />
                                <div className="name-tier">
                                    {renderTierName(activeIndex)}
                                </div>
                                <div className="right-arrow" onClick={handleNext}>
                                    <img src="/images/user/tier-right.png" alt="Next" />
                                </div>
                            </div>
                            <div className="images-tier-bot">
                                {imagesRender.map((img, index) => (
                                    <div
                                        key={index}
                                        className={`item ${index === activeIndex ? 'active' : ''}`}
                                    >
                                        <img src={img} alt={`Slide ${index}`} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="box-current-tier-page">
                            <div className="top">
                                <div className="text-left">
                                    Current Tier: <span>{renderTierName(dataInfo?.tier?.tier)}</span>
                                </div>
                                <div className="text-right">
                                    {dataInfo?.tier?.tier === 4 ?
                                        <>
                                            {""}
                                        </>
                                        :
                                        <>
                                            {renderTierName(dataInfo?.tier?.tier + 1)}
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="bottom">
                                <div className="bottom-process">
                                    {dataInfo?.tier?.tier === 4 ?
                                        <>
                                            <Progress percent={100} status="active" />
                                        </>
                                        :
                                        <>
                                            <Progress percent={dataInfo?.tier?.balance / dataInfo?.tier?.nextTier * 100} status="active" />
                                        </>
                                    }
                                </div>
                                <div className="bottom-number-stake">
                                    <div className="left">
                                        Your Stake:
                                    </div>
                                    <div className="right">
                                        <span>{dataInfo?.tier?.balance} </span>{dataInfo?.tier?.tier === 4 ? "" : `/ ${dataInfo?.tier?.nextTier} MEY`}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="group-button">
                            <button className="btn-info-action" onClick={handleCancel}>
                                Close
                            </button>
                            <button className="btn-info-action res">
                                <Link to="/apps/staking">
                                    Stake
                                </Link>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default Portfolio