const Flow = () => {
  return (
    <>
      <div className="container flow-main" id="how">
        <div
          className="flow-top"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <div className="icon-flow">
            <img src="/images/landing/icon-flow.png" alt="" />
          </div>
          <div className="title-offer">How It Works</div>
          <div className="img-bg-flo">
            <img src="/images/landing/img-bg-flo.png" alt="" />
          </div>
        </div>
        <div className="flow-body">
          <div className="list-how">
            <div
              className="wrap-item-how"
              data-aos="zoom-in"
              data-aos-duration="1200"
            >
              <div className="item-how">
                <div className="num-how">
                  <img
                    className="num-how1"
                    src="/images/landing/flow-num1.png"
                    alt=""
                  />
                  <img
                    className="num-how2"
                    src="/images/landing/num-how2.png"
                    alt=""
                  />
                </div>
                <div className="title-item-how">Sign Up</div>
                <div className="dec-item-how">
                  Connect your wallet to get started.
                </div>
              </div>
            </div>
            <div
              className="wrap-item-how"
              data-aos="zoom-in"
              data-aos-duration="1400"
            >
              <div className="item-how">
                <div className="num-how">
                  <img
                    className="num-how1"
                    src="/images/landing/flow-num2.png"
                    alt=""
                  />
                  <img
                    className="num-how2"
                    src="/images/landing/num-how2.png"
                    alt=""
                  />
                </div>
                <div className="title-item-how">Choose Your Investment</div>
                <div className="dec-item-how">
                  Stake, lend, or buy fractional real estate tokens.
                </div>
              </div>
            </div>
            <div
              className="wrap-item-how"
              data-aos="zoom-in"
              data-aos-duration="1600"
            >
              <div className="item-how">
                <div className="num-how">
                  <img
                    className="num-how1"
                    src="/images/landing/flow-num3.png"
                    alt=""
                  />
                  <img
                    className="num-how2"
                    src="/images/landing/num-how2.png"
                    alt=""
                  />
                </div>
                <div className="title-item-how">Manage & Trade</div>
                <div className="dec-item-how">
                  Use our marketplace to buy, sell, or trade your assets.
                </div>
              </div>
            </div>
            <div
              className="wrap-item-how"
              data-aos="zoom-in"
              data-aos-duration="1800"
            >
              <div className="item-how">
                <div className="num-how">
                  <img
                    className="num-how1"
                    src="/images/landing/flow-num4.png"
                    alt=""
                  />
                  <img
                    className="num-how2"
                    src="/images/landing/num-how2.png"
                    alt=""
                  />
                </div>
                <div className="title-item-how">Earn & Grow</div>
                <div className="dec-item-how">
                  Watch your investments grow with secure and transparent
                  returns.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Flow;
