/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState, useLayoutEffect } from "react";
import { Steps, Checkbox, Modal, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useContract } from "../../../../hooks/useContract";
import { BASE_SCAN, NUM_FORMAT, RPC_BASE, WORK_CHAIN } from "../../../../constants";
import abiTokenStake from "./utils/abiTokenStake.json";
import Web3 from "web3";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import Action from "./action";
import abiStake from "./utils/abiStake.json"

import { isMobile } from "react-device-detect";
import { useHookStaked } from '../storeStaked'
import { useAccount, useChainId } from "wagmi";
import { formatNumber } from "./utils";
import { Noti } from "../../../../components/Noti/toast";
import { AbiItem } from "web3-utils";

const web3: any = new Web3(RPC_BASE);

// const provider = "https://data-seed-prebsc-1-s1.binance.org:8545/";
declare const window: Window & typeof globalThis & { ethereum: any };
const UnStake = (props: any) => {
    let scStake = props?.poolContract
    let endTime = props?.endTime
    let tokenStake = props?.tokenContract
    const { selectedNetworkId }: any = useWeb3ModalState();
    const [state, actions] = useHookStaked()
    const { address } = useAccount();
    const chainId = useChainId()

    // const w3 = new web3(RPC_BASE)
    const bscsContract: any = useContract(tokenStake, abiTokenStake);
    const contractStake: any = useContract(scStake, abiStake);
    const contractStakeNotAddress = new web3.eth.Contract(
        abiStake as unknown as AbiItem,
        scStake,
    );

    // window.ethereum ? new web3(window.ethereum) : new web3(RPC_BASE);
    const [ethBalance, setEthBalance] = useState(0);
    const [current, setCurrent] = useState(0);
    const [amountBscs, setAmountBscs] = useState<any>(0);
    const [amountBscsPool, setAmountBscsPool] = useState<any>(0);
    const [hashUnStake, setHashUnStake]: any = useState('');
    const [isCheck, setIsCheck] = useState(false);
    const { Step } = Steps;
    const [showNext, setShowNext] = useState(false);
    const [amountValue, setAmountValue] = useState<any>();
    const [amountValueFormat, setAmountValueFormat] = useState<any>();
    const [unstakeAmount, setUnstakeAmount] = useState(0);
    const [isSuccess, setIsSuccess]: any = useState(false)
    const [timeLock, settimeLock]: any = useState(0)
    const [stakeTime, setstakeTime]: any = useState(0)
    const [childData, setchildData]: any = useState(false)
    const [amountReceive, setAmountReceive]: any = useState(0)
    const [amountFee, setAmountFee]: any = useState(0)
    const [numberFee, setNumberFee]: any = useState(0);

    const caculFee = () => {
        let tempFee = Number(amountValue) / 100 * numberFee
        let tempReceive = Number(amountValue) / 100 * (100 - numberFee)
        setAmountReceive(tempReceive)
        setAmountFee(tempFee)
    }


    useEffect(() => {
        if (amountValue) {
            caculFee()
        } else {
            setAmountReceive('--')
            setAmountFee('--')
        }

    }, [amountValue])

    const checkFlag = async () => {
        try {
            await contractStake.userMap(address).then((res: any) => {
                if (res) {

                    setUnstakeAmount(res.inUnStakeAmount / 1e18)
                } else {
                    setUnstakeAmount(0)
                }
            })
                .catch((err: any) => {
                    console.log('err userMap', err)
                    setUnstakeAmount(0)
                })
        } catch (error) {
            console.log('error userMap', error)
            setUnstakeAmount(0)
        }
    }

    useLayoutEffect(() => {
        if (contractStake && selectedNetworkId === WORK_CHAIN) {
            checkFlag()
        }

    }, [address, selectedNetworkId, contractStake, childData])



    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const onChange = (e: any) => {
        setIsCheck(e.target.checked)
    };

    const onChangeValue = (event: any) => {
        let x = event.target.value.replace(/[^0-9.]/g, "");

        if (Number(x) <= amountBscsPool) {
            setAmountValue(x)
            let inputValue = event.target.value;
            const filteredValue = inputValue.replace(/[^0-9.]/g, '');
            const parts = filteredValue.split('.');
            if (parts[0]) {
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }


            const formattedValue = parts.length > 1
                ? `${parts[0]}.${parts[1]}`
                : parts[0];
            setAmountValueFormat(formattedValue);
        }

    };

    const steps = [
        {
            title: '1',
            content: 'First-content',
        },
        {
            title: '2',
            content: '2-content',
        },
        {
            title: '3',
            content: '3-content',
        },
        {
            title: '4',
            content: '4-content',
        },
        {
            title: '5',
            content: 'Last-content',
        },
    ];

    const renderImg1 = () => {
        return (
            <img className="img-step-stake" src="/images/staking/dot-stake-ac.png" alt="" />
        )
    }
    const renderImg2 = () => {
        if (current < 1) {
            return <img className="img-step-stake" src="/images/staking/dot-stake.png" alt="" />
        } else {
            return <img className="img-step-stake" src="/images/staking/dot-stake-ac.png" alt="" />
        }
    }
    const renderImg3 = () => {
        if (current < 2) {
            return <img className="img-step-stake" src="/images/staking/dot-stake.png" alt="" />
        } else {
            return <img className="img-step-stake" src="/images/staking/dot-stake-ac.png" alt="" />
        }
    }
    const renderImg4 = () => {
        if (current < 3) {
            return <img className="img-step-stake" src="/images/staking/dot-stake.png" alt="" />
        } else {
            return <img className="img-step-stake" src="/images/staking/dot-stake-ac.png" alt="" />
        }
    }
    const renderImg5 = () => {
        if (current < 4) {
            return <img className="img-step-stake" src="/images/staking/dot-stake.png" alt="" />
        } else {
            return <img className="img-step-stake" src="/images/staking/dot-stake-ac.png" alt="" />
        }
    }

    const renderText1 = () => {
        return <span className="text-ac">Warning</span>
    }
    const renderText2 = () => {
        if (current < 1) {
            return "Checkpoints"
        } else {
            return <span className="text-ac">Checkpoints</span>
        }

    }

    const renderText3 = () => {
        if (current < 2) {
            return "Amount to Unstake"
        } else {
            return <span className="text-ac">Amount to Unstake</span>
        }

    }
    const renderText4 = () => {
        if (current < 3) {
            return "Initialize Unstake"
        } else {
            return <span className="text-ac">Initialize Unstake</span>
        }

    }
    const renderText5 = () => {
        if (current < 4) {
            return "Confirmation"
        } else {
            return <span className="text-ac">Confirmation</span>
        }

    }

    const [isModalOpen, setIsModalOpen] = useState(false);

    // const handleOk = () => {
    //     setIsModalOpen(false);
    // };

    // const handleCancel = () => {
    //     setIsModalOpen(false);
    // };

    const getBscs = async () => {
        if (bscsContract) {
            const amount = await bscsContract.balanceOf(address);
            setAmountBscs(amount.toString() / 1e18);
        }
    };

    useLayoutEffect(() => {
        if (address && selectedNetworkId === WORK_CHAIN) {
            getBscs();
        } else {
            setAmountBscs(0)
        }
    }, [address, selectedNetworkId, childData, bscsContract]);

    const getBscsPool = async () => {
        if (contractStake) {
            const amount = await contractStake.balanceOf(address);
            let balance: any = web3.utils.fromWei(amount, "ether");
            if (Number(balance) !== 0) {
                setAmountBscsPool(balance);
            } else {
                setAmountBscsPool(Number(balance));
            }

        }
    };

    useLayoutEffect(() => {
        if (address && selectedNetworkId === WORK_CHAIN) {
            getBscsPool();
        } else {
            setAmountBscsPool(0)
        }
    }, [address, selectedNetworkId, childData, bscsContract]);

    useLayoutEffect(() => {
        if (address && selectedNetworkId === WORK_CHAIN) {
            web3.eth.getBalance(address).then((res: any) => {
                if (res) {
                    let _balance: any = web3.utils.fromWei(res, "ether");
                    let ethBl = Math.floor(parseFloat(_balance) * 10000000) / 10000000;
                    setEthBalance(ethBl);
                }
            })
        } else {
            setEthBalance(0)
        }
    }, [address, chainId, selectedNetworkId, childData]);

    useLayoutEffect(() => {
        // if (address && amountBscs > 0 && ethBalance > 0 && isCheck && unstakeAmount <= 0 && amountBscsPool) {
        if (address && selectedNetworkId === WORK_CHAIN && ethBalance > 0 && isCheck && unstakeAmount <= 0 && amountBscsPool) {
            if (current === 2) {
                if (amountValue && Number(amountValue) <= amountBscsPool) {
                    setShowNext(true)
                } else {
                    setShowNext(false)
                }
            } else {
                setShowNext(true)
            }

        } else {
            setShowNext(false)
        }

    }, [address, selectedNetworkId, ethBalance, isCheck, unstakeAmount, amountValue, current, childData, bscsContract, contractStake])

    function isAllZeros(str: any) {
        return /^0+$/.test(str);
    }

    const handleMax = () => {
        setAmountValue(amountBscsPool)

        //
        const filteredValue = amountBscsPool?.toString().replace(/[^0-9.]/g, '');
        const parts = filteredValue.split('.');
        if (parts[0]) {
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        if (isAllZeros(parts[1]?.slice(0, 4))) {
            const formattedValue = parts.length > 1
                ? parts[0]
                //`${parts[0]}.${parts[1]?.slice(0, 4)}`
                : parts[0];
            setAmountValueFormat(formattedValue);
        } else {
            const formattedValue = parts.length > 1
                ? `${parts[0]}.${parts[1]?.slice(0, 4)}`
                : parts[0];
            setAmountValueFormat(formattedValue);
        }

    }



    const saveLog = async (hash: any, amount: any, type: any) => {
        try {
            let params = {
                ownerAddress: address,
                contractAddress: scStake,
                txHash: hash,
                amount: amount,
                type: type,
                assetToken: 'Mey',
            }
            await actions.addHistory(params)
        } catch (error) {

        }
    }

    const handleUnstake = async () => {

        try {
            setIsModalOpen(true);
            if (selectedNetworkId !== WORK_CHAIN) {
                setIsModalOpen(false);
                Noti('error', "Wrong network", 3000)
                return
            }
            let amount = web3.utils.toWei(amountValue.toString(), "ether");
            await contractStake.unstake(amount)
                .then(async (res: any) => {
                    saveLog(res?.hash, amountValue, 2)
                    // if (Number(amountValue) > 50000) {
                    //     const timestamp = new Date().getTime();
                    //     const date1 = new Date(timestamp);
                    //     let timeStake = date1.toLocaleString('en-GB')
                    //     let paramNoti = {
                    //         projectName: "BSCS",
                    //         amount: amountValue,
                    //         actionType: 2,
                    //         userAddress: address,
                    //         tokenName: "BSCS",
                    //         actionTime: timeStake,
                    //         hash: res?.hash,
                    //         status: "Waiting"
                    //     }
                    //     await actions.callNoti(paramNoti)
                    // }

                    // let params = {
                    //     ownerAddress: address,
                    //     poolContract: scStake
                    // }

                    // let resapi = await actions.updateStaked(params)
                    // if (resapi.data.data) {
                    res.wait()
                        .then(async (resWait: any) => {
                            if (resWait?.status === 1) {
                                // const timestamp = new Date().getTime();
                                // const date1 = new Date(timestamp);
                                // let timeStake = date1.toLocaleString('en-GB')
                                // let paramNoti = {
                                //     projectName: "BSCS",
                                //     amount: amountValue,
                                //     actionType: 2,
                                //     userAddress: address,
                                //     tokenName: "BSCS",
                                //     actionTime: timeStake,
                                //     hash: resWait?.transactionHash,
                                //     status: "Success"
                                // }
                                // await actions.callNoti(paramNoti)
                                // console.log('resWait.transactionHash', resWait.transactionHash)
                                setHashUnStake(resWait.transactionHash)

                                Noti('success', "UnStake successfully", 3000)
                                props.setIsCheckAction(!props.isCheckAction)
                                setIsModalOpen(false);
                                setCurrent(current + 1);
                                setIsSuccess(!isSuccess)
                                //-----

                            } else {
                                Noti('error', "Unknown error (status hash fail)", 3000)
                                setIsModalOpen(false);
                            }

                        })
                        .catch((err: any) => {
                            Noti('error', "Unknown error", 3000)
                            setIsModalOpen(false);
                        })
                    // } else {
                    //     message.error({
                    //         type: "error",
                    //         content: "API update status stake fail",
                    //         className: "custom-class",
                    //         duration: 3,
                    //     });
                    //     setIsModalOpen(false);
                    // }
                })
                .catch((err: any) => {
                    console.log('err', err)
                    Noti('error', err.reason || err?.message, 3000)
                    setIsModalOpen(false);
                })
        } catch (error: any) {
            console.log('err catch-->', error)
            Noti('error', error, 3000)
            setIsModalOpen(false);

        }
    }

    const callbackFunction = (data: any) => {
        setchildData(data)
        props.setIsCheckAction(!props.isCheckAction)
    };
    const callbackFunction1 = (data: any) => {
        settimeLock(data?.timeLock)
        setstakeTime(data?.stakeTime)

    };

    const onChangeAll = () => {
        setIsCheck(!isCheck)
    };


    const getNumberFee = async () => {
        try {
            await contractStakeNotAddress.methods
                .earlyWithdrawFee()
                .call()
                .then((res: any) => {
                    setNumberFee(Number(res) / 100);
                })
                .catch((err: any) => {
                    setNumberFee(0);
                });
        } catch (error) {
            setNumberFee(0);
        }
    };



    useEffect(() => {
        if (contractStakeNotAddress) {
            getNumberFee()
        }
    }, [contractStakeNotAddress])



    return (
        <div className="wrap-stake">
            {isMobile ? ('') : (
                <div className="line-status">
                    {/* @ts-ignore this lib is incompatible with react18  */}
                    <Steps

                        current={current}>
                        <Step
                            title={renderText1()}
                            icon={renderImg1()}
                        />
                        <Step
                            title={renderText2()}
                            icon={renderImg2()}
                        />
                        <Step
                            title={renderText3()}
                            icon={renderImg3()}
                        />
                        <Step
                            title={renderText4()}
                            icon={renderImg4()}
                        />
                        <Step
                            title={renderText5()}
                            icon={renderImg5()}
                        />
                    </Steps>
                </div>
            )}

            <div className="stake">
                <div className="wrap-all">
                    <div className="content-stake">
                        {/* <div className="steps-content">{steps[current].content}</div> */}
                        {current === 0 ? (
                            <div className="content-step1">
                                <div className="stake-your-token">Warning</div>
                                <div className="unstake-warning">
                                    <div className="img-warning">
                                        <img src="/images/staking/unstake-warning.png" alt="" />
                                    </div>
                                    <div className="content-warning1">
                                        After Unstaking, you must wait 7 days before you can withdraw your MEY token.
                                    </div>
                                    <div className="content-warning2">
                                        Unstake before the deadline will be encountered a {numberFee}% penalty fee.
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                {current === 1 ? (
                                    <div className="content-step2">
                                        <div className="title">Checkpoints</div>
                                        <div className="des">The following conditions must be met to proceed</div>
                                        <div className="item-step1">
                                            <div className="card-item">
                                                <div className={`${address ? 'check-card acive' : 'check-card'}`}>
                                                    <img src="/images/staking/check-a1.png" alt="" />
                                                    <img src="/images/staking/check-b1.png" alt="" />
                                                </div>
                                                <div className="title-card">Connected with Wallet</div>
                                                <div className="content-card">If not connected, click the "Connect Wallet" button in the top right corner</div>
                                            </div>
                                            <div className="card-item">
                                                <div className={`${amountBscsPool > 0.0001 ? 'check-card acive' : 'check-card'}`}>
                                                    <img src="/images/staking/check-a1.png" alt="" />
                                                    <img src="/images/staking/check-b1.png" alt="" />

                                                </div>
                                                <div className="title-card">Have an active MEY token stake</div>
                                                <div className="content-card cus">You currently have {formatNumber(amountBscsPool, NUM_FORMAT)} MEY token staked</div>
                                            </div>
                                            <div className="card-item">
                                                <div className={`${ethBalance > 0 ? 'check-card acive' : 'check-card'}`}>
                                                    <img src="/images/staking/check-a1.png" alt="" />
                                                    <img src="/images/staking/check-b1.png" alt="" />
                                                </div>
                                                <div className="title-card">ETH available in wallet</div>
                                                <div className="content-card">ETH is required to pay transaction fees on the Base Chain network.<br /><span>ETH Balance: {formatNumber(ethBalance, 4)}</span></div>
                                            </div>
                                            <div className="card-item">
                                                <div className={`${unstakeAmount === 0 && address ? 'check-card acive' : 'check-card'}`}>
                                                    <img src="/images/staking/check-a1.png" alt="" />
                                                    <img src="/images/staking/check-b1.png" alt="" />
                                                </div>
                                                <div className="title-card">Eligible to initiate unstake</div>
                                                <div className="content-card">You cannot unstake if you already have an active MEY unstake/withdrawal request</div>
                                            </div>
                                        </div>
                                        <div className="check-real" onClick={onChangeAll}>
                                            <Checkbox checked={isCheck} onChange={onChange} /> <span>I have read the <a target="_blank" href="/Term" className="terms">Terms and Conditions</a></span>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {current === 2 ? (
                                            <div className="content-step3">
                                                <div className="title title-unstake">
                                                    Amount to Unstake
                                                </div>

                                                <div className="content-input unstake">
                                                    <div className="amount-stake-title fix">
                                                        <div className="title-left">
                                                            You have {" "}
                                                            <span>
                                                                {formatNumber(amountBscsPool, NUM_FORMAT)}
                                                                {" "}Mey
                                                            </span>
                                                            {" "} tokens available that can be Unstake now
                                                        </div>
                                                        {/* <div className="title-right"><span>50,000</span> BSCS available</div> */}
                                                    </div>

                                                    <div className="amount-stake-title">
                                                        <div className="title-left">
                                                            Please enter the amount of Mey token you want to unstake
                                                        </div>
                                                        {/* <div className="title-right"><span>50,000</span> BSCS available</div> */}
                                                    </div>

                                                    <div className="input-amount">
                                                        <input type="text" className="amount-stake" placeholder="0" onChange={onChangeValue} value={amountValueFormat} min="0" max={amountBscsPool} />
                                                        {/* <input className="amount-stake" placeholder="0.0" onChange={onChangeValue} value={amountValue} type="text" /> */}
                                                        <div className="posi-unstake">
                                                            {/* <span className="palce-token">BSCS</span> */}
                                                            <button className="btn-max-stake" onClick={handleMax}>Max</button>
                                                        </div>
                                                    </div>
                                                    {(stakeTime * 1000 + timeLock * 1000 + 5000) > Date.now() ? (
                                                        <div className="due-fee">
                                                            <p className="due-fee-text">
                                                                Due to unstaking early, you will be penalized with a {numberFee}% fee, equivalent to{" "}
                                                                <span>{formatNumber(amountFee, NUM_FORMAT)} </span>
                                                                {" "}tokens.
                                                            </p>
                                                            <p className="due-fee-text">
                                                                You will receive
                                                                <span>{" "}{formatNumber(amountReceive, NUM_FORMAT)} {" "}</span>
                                                                tokens after unstaking.
                                                            </p>
                                                        </div>
                                                    ) : ('')}

                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                {current === 3 ? (
                                                    <div className="content-step4">
                                                        <div className="title">Confirm Unstaking Process</div>
                                                        <div className="confirm-content">
                                                            <div className="cf-content1">In this step, you initiate the unstaking process.</div>
                                                            <div className="cf-content1">After a 7 days waiting period, you will be allowed to withdraw your MEY token.</div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {current === 4 ? (
                                                            <div className="content-step5">
                                                                <div className="title">Confirmation</div>
                                                                <div className="des">You have initiated the unstaking process and started the 7 day timer.</div>
                                                                <div className="success-content">
                                                                    <div className="img-success">
                                                                        <img src="/images/staking/stake-success.png" alt="" />
                                                                    </div>
                                                                    <div className="mid-content">Confirmed</div>
                                                                    <div className="mid-content1">
                                                                        You have initiated the unstaking process and started the 7 day timer.
                                                                    </div>
                                                                    <div className="mid-content2">
                                                                        You can check on Basescan to confirm the transaction.

                                                                    </div>
                                                                    {isMobile ? (
                                                                        <a className="mid-content3" target="_blank" rel="noreferrer" href={`${BASE_SCAN}/tx/${hashUnStake}`}>
                                                                            Txn Hash: {hashUnStake.slice(0, 10)}...{hashUnStake.slice(-10)}
                                                                        </a>
                                                                    ) : (
                                                                        <a className="mid-content3" target="_blank" rel="noreferrer" href={`${BASE_SCAN}/tx/${hashUnStake}`}>
                                                                            Txn Hash: {hashUnStake.slice(0, 20)}...{hashUnStake.slice(-20)}
                                                                        </a>
                                                                    )}

                                                                </div>
                                                            </div>
                                                        ) : ('')}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <div className="steps-action">
                        {current > 0 && current !== steps.length - 1 && (
                            <button className="btn-control" onClick={() => prev()}>
                                <img src="/images/staking/icon-pre.png" alt="" />
                            </button>
                        )}
                        {current === 0 ?
                            <>
                                <button className="btn-control" onClick={() => next()}>
                                    <img src="/images/staking/icon-next.png" alt="" />
                                </button>
                            </>
                            :
                            <>
                                {current < steps.length - 1 && (
                                    <>
                                        {showNext ? (
                                            <>
                                                {current === 3 ?
                                                    <>
                                                        <button className="btn-control" onClick={handleUnstake}>
                                                            <img src="/images/staking/icon-next.png" alt="" />
                                                        </button>

                                                    </>
                                                    :
                                                    <>
                                                        <button className="btn-control" onClick={() => next()}>
                                                            <img src="/images/staking/icon-next.png" alt="" />
                                                        </button>

                                                    </>
                                                }
                                            </>
                                        ) : (
                                            <button className="btn-control dis">
                                                <img src="/images/staking/icon-next.png" alt="" />
                                            </button>

                                        )}
                                    </>
                                )}
                            </>
                        }
                        {current === steps.length - 1 && (
                            <button className="btn-control done" onClick={() => window.location.reload()}> Done</button>

                        )}
                    </div>
                </div>

                {!isMobile ? ('') : (
                    <>

                        <div className="line-status">
                            {/* @ts-ignore this lib is incompatible with react18  */}
                            <Steps

                                current={current}>
                                <Step
                                    title={renderText1()}
                                    icon={renderImg1()}
                                />
                                <Step
                                    title={renderText2()}
                                    icon={renderImg2()}
                                />
                                <Step
                                    title={renderText3()}
                                    icon={renderImg3()}
                                />
                                <Step
                                    title={renderText4()}
                                    icon={renderImg4()}
                                />
                                <Step
                                    title={renderText5()}
                                    icon={renderImg5()}
                                />
                            </Steps>
                        </div>
                        <div className="line-mo"></div>
                    </>

                )}
                <Action
                    scStake={scStake}
                    tokenStake={tokenStake}
                    isSuccess={isSuccess}
                    tabBox={props.tabBox}
                    parentCallback1={callbackFunction1}
                    parentCallback={callbackFunction}
                    endTime={endTime}
                />

            </div>


            {/* @ts-ignore this lib is incompatible with react18  */}
            <Modal
                title="Unstaking"
                visible={isModalOpen}
                // onOk={handleOk}
                // onCancel={handleCancel}
                footer={false}
                wrapClassName="modal-confirm"
            >
                <p className="modal-text1">Your request is being processed. Please wait...</p>
                <p className="spin-stake">
                    <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 55 }} spin />} />
                </p>

            </Modal>
        </div>


    )
}

export default UnStake;