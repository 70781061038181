import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useAccount } from "wagmi";
import { AppHome } from '../../../../api/appHome';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const ChartJs = () => {
    const { address } = useAccount();
    const [activeIndex, setActiveIndex] = useState(1)
    const [dataChart , setDataChart]: any = useState()

    const SettingDataChart = async () => {
        try {
            let res = await AppHome.getDataChart(address , activeIndex);
            if (res.data.success === true) {
                setDataChart(res.data.data);
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        if (address) {
            SettingDataChart()
        }
    }, [address , activeIndex]);

    const dataWeek: any = {
        labels: dataChart?.labels,
        datasets: [
            {
                label: 'P2p Lending',
                data:  dataChart && dataChart?.datasets[0]?.data[0].data,
                fill: false,
                borderColor: '#00EFFF',
                tension: 0.4,
            },
            {
                label: 'PTO',
                data: dataChart && dataChart?.datasets[0]?.data[1].data,
                fill: false,
                borderColor: '#2B79F7',
                tension: 0.4,
            },
            {
                label: 'Staking',
                data: dataChart && dataChart?.datasets[0]?.data[2].data,
                fill: false,
                borderColor: '#C4A3FF',
                tension: 0.4,
            }
        ],
    };

    const optionsWeek: any = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,
            },
            tooltip: {
                enabled: true,
                backgroundColor: '#3B3F45',
                titleFont: {
                    size: 16,
                    weight: 'bold',
                    family: 'Inter',
                },
                bodyFont: {
                    size: 14,
                    family: 'Inter',
                    weight: 'bold',
                },
                padding: 15,
                displayColors: false,
                callbacks: {
                    title: function (tooltipItems: any) {
                        return `${tooltipItems[0].label}`;
                    },
                    label: function (tooltipItem: any) {
                        return `Account Value`;
                    },
                    afterLabel: function (tooltipItem: any) {
                        return `${tooltipItem.formattedValue} MEY`;
                    },
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },
            },
        },
    };

    const dataMonth: any = {
        labels: dataChart?.labels,
        datasets: [
            {
                label: 'P2p Lending',
                data:  dataChart && dataChart?.datasets[0]?.data[0].data,
                fill: false,
                borderColor: '#00EFFF',
                tension: 0.4,
            },
            {
                label: 'PTO',
                data: dataChart && dataChart?.datasets[0]?.data[1].data,
                fill: false,
                borderColor: '#2B79F7',
                tension: 0.4,
            },
            {
                label: 'Staking',
                data: dataChart && dataChart?.datasets[0]?.data[2].data,
                fill: false,
                borderColor: '#C4A3FF',
                tension: 0.4,
            }
        ],
    };

    const optionsMonth: any = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,
                enabled: true,
            },
            tooltip: {
                enabled: true,
                backgroundColor: '#3B3F45',
                titleFont: {
                    size: 16,
                    weight: 'bold',
                    family: 'Inter',
                },
                bodyFont: {
                    size: 14,
                    family: 'Inter',
                    weight: 'bold',
                },
                padding: 15,
                displayColors: false,
                callbacks: {
                    title: function (tooltipItems: any) {
                        return `${tooltipItems[0].label}`;
                    },
                    label: function (tooltipItem: any) {
                        return `Account Value`;
                    },
                    afterLabel: function (tooltipItem: any) {
                        return `${tooltipItem.formattedValue} MEY`;
                    },
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },
            },
        },
    };

    return (
        <>
            <div className="flex-chart">
                <div className="flex-top">
                    <div className="txt-left">
                        Accumulated Profit
                    </div>
                    <div className="txt-right">
                        <div className={activeIndex === 1 ? "item active" : "item"} onClick={() => setActiveIndex(1)}>
                            Weekly
                        </div>
                        <div className={activeIndex === 2 ? "item active" : "item"} onClick={() => setActiveIndex(2)}>
                            Monthly
                        </div>
                    </div>
                </div>
                <div className="flex-bottom">
                    {activeIndex === 1 ?
                        <>
                            <div className="box-line-js">
                                <Line data={dataWeek} options={optionsWeek} />
                                <img src="/images/user/my-chart.png" alt="" />
                            </div>
                        </>
                        :
                        <>
                            <div className="box-line-js">
                                <Line data={dataMonth} options={optionsMonth} />
                                <img src="/images/user/my-chart.png" alt="" />
                            </div>
                        </>
                    }
                    <div className="note-line">
                        <div className="item">
                            <div className="icon">
                                <img src="/images/user/linn-s-1.png" alt="" />
                            </div>
                            <div className="name-line">
                                P2P Lending
                            </div>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <img src="/images/user/line-s-2.png" alt="" />
                            </div>
                            <div className="name-line">
                                PTO
                            </div>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <img src="/images/user/line-s-3.png" alt="" />
                            </div>
                            <div className="name-line">
                                Staking
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ChartJs