import React, { useEffect, useMemo, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { base, baseSepolia } from "wagmi/chains";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { WagmiProvider } from "wagmi";

import LandingPage from "./pages/landing-page";

import "./App.css";
import Main from "./components/main";
import DetailsP2pLending from "./pages/apps/p2p-lending/details";
import StakingList from "./pages/apps/staking";
import StakeDetail from "./pages/apps/staking/details";
import P2pLending from "./pages/apps/p2p-lending";
import HomePage from "./pages/apps/home-page";
import Portfolio from "./pages/apps/porfolio";
import EditProfile from "./pages/apps/porfolio/edit-profile";
import Pto from "./pages/apps/pto";
import DetailsPto from "./pages/apps/pto/details";
import Vesting from "./pages/apps/porfolio/vesting";
import Policy from "./pages/polycy";
import Terms from "./pages/terms";
import Affiliate from "./pages/apps/afiliate";
import ComingSoon from "./components/comming-soon";
import HistoryPage from "./pages/apps/history-page";
import { ConfigProvider } from "antd";
import Marketplace from "./pages/apps/marketplace";
import MarketplaceDetail from "./pages/apps/marketplace/detail";
import Ranked from "./pages/apps/porfolio/rank";
import { AppHome } from "./api/appHome";
import VerifyEmail from "./pages/apps/porfolio/verify-email";


function App() {
    // start config wagmi wallet
    const queryClient = new QueryClient();
    // 1. Get projectId at https://cloud.walletconnect.com
    const projectId = "5f6c18132c6e21af2b276b34ad0af7ec";
    // 2. Create wagmiConfig
    const metadata = {
        name: "Web3Modal",
        description: "Web3Modal",
        url: "https://web3modal.com", // origin must match your domain & subdomain
        icons: ["https://avatars.githubusercontent.com/u/37784886"],
    };
    // const chains = [baseSepolia] as const;
    const chains = [base] as const;
    // console.log('chains', chains)
    const config = defaultWagmiConfig({
        chains, // required
        projectId, // required
        metadata, // required
        enableInjected: false, // Optional - true by default
        enableWalletConnect: true, // Optional - true by default
        enableEIP6963: true, // Optional - true by default
        enableCoinbase: false, // Optional - true by default
    });
    createWeb3Modal({
        wagmiConfig: config,
        projectId,
        enableAnalytics: true, // Optional - defaults to your Cloud configuration
    });
    // end config wagmi wallet
    console.log("process", process.env.NODE_ENV);
    return (
        <div className="App">
            <WagmiProvider config={config}>
                <QueryClientProvider client={queryClient}>
                    <ConfigProvider
                        theme={{
                            token: {
                                colorPrimary: "#2b79f7",
                            },
                            components: {
                                Popover: {
                                    colorBgElevated: "#191B1D",
                                },
                                Select: {
                                    colorBgContainer: "#3b3f4566",
                                    colorBgElevated: "#3b3f4566",
                                    colorBorder: "#3b3f4566",
                                    colorIcon: "#FFFFFF",
                                    colorTextPlaceholder: "#FFFFFF",
                                    colorText: "#FFFFFF",
                                    selectorBg: "#3b3f4566",
                                    // optionActiveBg: "#FFFFFF",
                                    // optionSelectedBg: "#FFFFFF",
                                    // optionSelectedColor: "#FFFFFF",
                                },
                                Slider: {
                                    trackBg: "#00EFFF",
                                    railBg: "#FFFFFF",
                                    railHoverBg: "#FFFFFF",
                                },
                                Table: {
                                    colorIcon: "#686a6d",
                                    colorIconHover: "#fff",
                                    colorBgContainer: "#1A1C1E",
                                    headerBg: "#191B1D",
                                    headerColor: "#ffffff99",
                                    borderColor: "#3b3f4566",
                                    cellFontSizeSM: 16,
                                    fontWeightStrong: 500,
                                    colorText: "#FFF",
                                },
                                Pagination: {
                                    itemActiveBg: "#2b79f7",
                                    itemSize: 29,
                                    itemBg: "#1A1C1E",
                                    borderRadius: 8,
                                    colorBorder: "#3b3f4566",
                                    colorText: "#FFF",
                                    colorTextDisabled: "#3b3f4566",
                                    fontFamily: "Radio Canada, sans-serif",
                                },
                            },
                        }}
                    >
                        <Main>
                            <Routes>
                                <Route path="/" element={<LandingPage />} />
                                <Route path="/PrivacyPolicy" element={<Policy />} />
                                <Route path="/Term" element={<Terms />} />
                                <Route path="/apps/" element={<HomePage />} />



                                {/* <Route path="/apps/p2p-lending" element={<P2pLending />} />
                                <Route
                                    path="/apps/p2p-lending/details"
                                    element={<DetailsP2pLending />}
                                /> */}
                                <Route path="/apps/p2p-lending" element={<ComingSoon />} />
                                <Route
                                    path="/apps/p2p-lending/details"
                                    element={<ComingSoon />}
                                />

                                <Route path="/apps/staking" element={<StakingList />} />
                                <Route path="/apps/staking/details" element={<StakeDetail />} />
                                <Route path="/apps/portfolio" element={<Portfolio />} />
                                <Route path="/apps/edit-profile" element={<EditProfile />} />
                                <Route path="/apps/verify-email" element={<VerifyEmail />} />

                                {/* <Route path="/apps/vesting" element={<Vesting />} /> */}
                                <Route path="/apps/vesting" element={<ComingSoon />} />

                                {/* <Route path="/apps/pto" element={<Pto />} />
                                <Route path="/apps/pto/details" element={<DetailsPto />} /> */}
                                <Route path="/apps/pto" element={<ComingSoon />} />
                                <Route path="/apps/pto/details" element={<ComingSoon />} />

                                {/* <Route path="/apps/marketplace" element={<Marketplace />} /> */}
                                {/* <Route
                                    path="/apps/marketplace/:id"
                                    element={<MarketplaceDetail />}
                                /> */}
                                <Route path="/apps/marketplace" element={<ComingSoon />} />

                                <Route path="/apps/affiliate" element={<Affiliate />} />
                                {/* <Route path="/apps/affiliate" element={<ComingSoon />} /> */}

                                <Route path="/apps/rank" element={<Ranked />} />
                                <Route path="/apps/history" element={<HistoryPage />} />
                                <Route path="/apps/coming-soon" element={<ComingSoon />} />
                                <Route path="*" element={<Navigate to="/" replace />} />
                            </Routes>
                        </Main>
                    </ConfigProvider>
                </QueryClientProvider>
            </WagmiProvider>
        </div>
    );
}

export default App;
