import { instance } from "../apiBase/instance";
import { AddRefPayload } from "../types/affiliate";

export const affiliateApi = {
  getDashboard: async (ownerAddress?: string) =>
    await instance.get("/affiliate/dashboard/", {
      params: {
        ownerAddress,
      },
    }),
    getClaimRef(params: any) {
        return instance.post<any>(`/affiliate/claim` , params);
    },
    getHisRef(params: any) {
        return instance.post<any>(`/affiliate/history`, params);
    },
  addRef: async (data: AddRefPayload) =>
    await instance.post("/affiliate/ref", data),
  getReferralCommission: async ({
    ownerAddress,
    pageIndex,
  }: {
    ownerAddress?: string;
    pageIndex?: number;
  }) =>
    await instance.get("/affiliate/list-reward", {
      params: {
        ownerAddress,
        pageIndex,
        pageSize: 5,
      },
    }),
};
