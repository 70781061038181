/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import web3 from "web3";
import { Steps, Checkbox, Modal, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import Action from "./action";
import { useContract } from "../../../../hooks/useContract";
import abiStake from "./utils/abiStake.json"
import { BASE_SCAN, NUM_FORMAT, RPC_BASE, WORK_CHAIN } from "../../../../constants";
import { isMobile } from "react-device-detect";
import { useHookStaked } from '../storeStaked'
import { useAccount, useChainId } from "wagmi";
import { formatNumber } from "./utils";
import { Noti } from "../../../../components/Noti/toast";


const steps = [
    {
        title: '1',
        content: 'First-content',
    },
    {
        title: '2',
        content: '2-content',
    },
    {
        title: '3',
        content: '3-content',
    },
];

declare const window: Window & typeof globalThis & { ethereum: any };

const Withdraw = (props: any) => {
    let scStake = props?.poolContract
    let tokenStake = props?.tokenContract
    let endTime = props?.endTime
    const { selectedNetworkId }: any = useWeb3ModalState();
    const { address } = useAccount();
    const chainId = useChainId()

    const [state, actions] = useHookStaked()
    const contractStake: any = useContract(scStake, abiStake);
    const [getUser, setGetUser] = useState<any>();
    const [ethBalance, setEthBalance] = useState(0);
    const w3 = new web3(RPC_BASE)
    const [showNext, setShowNext] = useState(false);
    const [isCheckTerm, setIsCheckTerm] = useState(false);
    const [current, setCurrent] = useState(0);
    const [timeUnstake, setTimeUnstake] = useState<any>();
    const [hashUnWr, setHashWr]: any = useState('');
    const [isSuccess, setIsSuccess]: any = useState(false)
    const [childData, setchildData]: any = useState(false)
    const { Step } = Steps;

    const [lockTimeUnstake, setlockTimeUnstake]: any = useState(0)

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const renderImg1 = () => {
        return (
            <img className="img-step-stake" src="/images/staking/dot-stake-ac.png" alt="" />
        )
    }
    const renderImg2 = () => {
        if (current < 1) {
            return <img className="img-step-stake" src="/images/staking/dot-stake.png" alt="" />
        } else {
            return <img className="img-step-stake" src="/images/staking/dot-stake-ac.png" alt="" />
        }
    }
    const renderImg5 = () => {
        if (current < 2) {
            return <img className="img-step-stake" src="/images/staking/dot-stake.png" alt="" />
        } else {
            return <img className="img-step-stake" src="/images/staking/dot-stake-ac.png" alt="" />
        }
    }

    const renderText1 = () => {
        return <span className="text-ac">Checkpoints</span>
    }
    const renderText2 = () => {
        if (current < 1) {
            return "Initialize Withdrawal"
        } else {
            return <span className="text-ac">Initialize Withdrawal</span>
        }
    }

    const renderText5 = () => {
        if (current < 4) {
            return "Confirmation"
        } else {
            return <span className="text-ac">Confirmation</span>
        }
    }

    const onChange = (e: any) => {
        setIsCheckTerm(e.target.checked)
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };



    useEffect(() => {
        if (address && selectedNetworkId === WORK_CHAIN) {
            w3.eth.getBalance(address).then((res: any) => {
                if (res) {
                    let _balance: any = web3.utils.fromWei(res, "ether");
                    let ethBl = Math.floor(parseFloat(_balance) * 10000000) / 10000000;
                    setEthBalance(ethBl);
                }
            })
        } else {
            setEthBalance(0)
        }
    }, [address, chainId, selectedNetworkId, current, childData]);

    const handleShowNext = () => {
        if (address && selectedNetworkId === WORK_CHAIN && ethBalance > 0 && isCheckTerm && getUser > 0 && ((timeUnstake * 1000 + lockTimeUnstake * 1000) <= date)) {
            setShowNext(true)
        } else {
            setShowNext(false)
        }
    }
    useEffect(() => {
        handleShowNext()
    }, [address, ethBalance, selectedNetworkId, current, isCheckTerm, current, getUser, childData])

    let date = Date.now();


    const getUserMap = async () => {
        if (contractStake) {
            await contractStake.userMap(address).then((user: any) => {
                setTimeUnstake(Number(user?.unstakeTime))
                setGetUser(user?.inUnStakeAmount / 1e18);
            })
                .catch((err: any) => {
                    console.log('err userMap', err)
                })

            await contractStake.lockTimePeriod().then((time2: any) => {
                setlockTimeUnstake(Number(time2))
            })


        }
    };

    useEffect(() => {
        if (address && selectedNetworkId === WORK_CHAIN) {
            getUserMap();
        } else {
            setTimeUnstake(0)
            setGetUser(0);
        }
    }, [address, current, selectedNetworkId, childData, contractStake]);

    const saveLog = async (hash: any, amount: any, type: any) => {
        try {
            let params = {
                ownerAddress: address,
                contractAddress: scStake,
                txHash: hash,
                amount: amount,
                type: type,
                assetToken: 'Mey',
            }
            await actions.addHistory(params)
        } catch (error) {

        }
    }


    const handleWithdraw = async () => {
        try {
            setIsModalOpen(true);
            if (selectedNetworkId !== WORK_CHAIN) {
                setIsModalOpen(false);
                Noti('error', "Wrong network", 3000)
                return
            }
            await contractStake.withdraw()
                .then(async (res: any) => {
                    saveLog(res?.hash, getUser, 7)
                    // const timestamp = new Date().getTime();
                    // const date1 = new Date(timestamp);
                    // let timeStake = date1.toLocaleString('en-GB')

                    // let paramNoti = {
                    //     projectName: "BSCS",
                    //     amount: getUser,
                    //     actionType: 3,
                    //     userAddress: address,
                    //     tokenName: "BSCS",
                    //     actionTime: timeStake,
                    //     hash: res.hash,
                    //     status: "Waiting"
                    // }
                    // await actions.callNoti(paramNoti)

                    res.wait()
                        .then(async (resWait: any) => {
                            if (resWait?.status === 1) {
                                // const timestamp = new Date().getTime();
                                // const date1 = new Date(timestamp);
                                // let timeStake = date1.toLocaleString('en-GB')

                                // let paramNoti = {
                                //     projectName: "BSCS",
                                //     amount: getUser,
                                //     actionType: 3,
                                //     userAddress: address,
                                //     tokenName: "BSCS",
                                //     actionTime: timeStake,
                                //     hash: resWait?.transactionHash,
                                //     status: "Success"
                                // }
                                // await actions.callNoti(paramNoti)

                                setHashWr(resWait?.transactionHash)
                                Noti('success', "Withdraw successfully", 3000)

                                props.setIsCheckAction(!props.isCheckAction)
                                setIsSuccess(!isSuccess)
                                setIsModalOpen(false);
                                setCurrent(current + 1);
                                //--
                            } else {
                                Noti('error', "Unknown error (status hash fail)", 3000)
                                setIsModalOpen(false);
                            }

                        })
                        .catch((err: any) => {
                            Noti('error', "Unknown error", 3000)
                            setIsModalOpen(false);
                        })
                })
                .catch((err: any) => {
                    console.log('err', err)
                    setIsModalOpen(false);
                    Noti('error', err.reason || err?.message, 3000)
                })
        } catch (error: any) {
            console.log('err catch-->', error)
            Noti('error', error, 3000)
            setIsModalOpen(false);

        }
    }

    const callbackFunction = (data: any) => {

        setchildData(data)
        props.setIsCheckAction(!props.isCheckAction)
    };
    const callbackFunction1 = (data: any) => {
    };
    const onChangeAll = () => {
        setIsCheckTerm(!isCheckTerm)
    };


    return (
        <div className="wrap-stake">
            {isMobile ? ('') : (
                <>
                    <div className="line-status">
                        {/* @ts-ignore this lib is incompatible with react18  */}
                        <Steps current={current}>
                            <Step
                                title={renderText1()}
                                icon={renderImg1()}
                            />
                            <Step
                                title={renderText2()}
                                icon={renderImg2()}
                            />

                            <Step
                                title={renderText5()}
                                icon={renderImg5()}
                            />
                        </Steps>
                    </div>
                </>
            )}

            <div className="stake">
                <div className="wrap-all">
                    {/* <div className="stake-your-token">Stake your MEY/div> */}
                    <div className="content-stake wd">

                        {/* <div className="steps-content">{steps[current].content}</div> */}
                        {current === 0 ? (
                            <div className="content-step1">
                                <div className="title">Checkpoints</div>
                                <div className="des">The following conditions must be met to proceed:</div>
                                <div className="item-step1">
                                    <div className="card-item">
                                        <div className={`${address ? 'check-card acive' : 'check-card'}`}>
                                            <img src="/images/staking/check-a1.png" alt="" />
                                            <img src="/images/staking/check-b1.png" alt="" />
                                        </div>
                                        <div className="title-card">Connected with Wallet</div>
                                        <div className="content-card">If not connected, click the "Connect Wallet" button in the top right corner</div>
                                    </div>
                                    <div className="card-item">
                                        <div className={`${(timeUnstake * 1000 + lockTimeUnstake * 1000) <= date && getUser > 0 ? 'check-card acive' : 'check-card'}`}>

                                            <img src="/images/staking/check-a1.png" alt="" />
                                            <img src="/images/staking/check-b1.png" alt="" />
                                        </div>
                                        <div className="title-card">7 days waiting period elapsed</div>
                                        {/* <div className="content-card">Current Balance: 0.0000</div> */}
                                    </div>
                                    <div className="card-item">
                                        <div className={`${ethBalance > 0 ? 'check-card acive' : 'check-card'}`}>
                                            <img src="/images/staking/check-a1.png" alt="" />
                                            <img src="/images/staking/check-b1.png" alt="" />
                                        </div>
                                        <div className="title-card">ETH available in wallet</div>
                                        <div className="content-card">ETH is required to pay transaction fees on the Base Chain network.<br /><span>ETH Balance: {formatNumber(ethBalance, NUM_FORMAT)}</span></div>
                                    </div>
                                    <div className="card-item">
                                        <div className={`${getUser > 0 ? 'check-card acive' : 'check-card'}`}>
                                            <img src="/images/staking/check-a1.png" alt="" />
                                            <img src="/images/staking/check-b1.png" alt="" />
                                        </div>
                                        <div className="title-card">You have Unstaked your MEY token</div>
                                        {/* <div className="content-card">You cannot stake if you have an active BSCS unstake/withdrawal request</div> */}
                                    </div>
                                </div>
                                <div className="check-real" onClick={onChangeAll}>
                                    <Checkbox checked={isCheckTerm} onChange={onChange} /> <span>I have read the <a target="_blank" href="/Term" className="terms">Terms and Conditions</a></span>
                                </div>
                            </div>
                        ) : (
                            <>
                                {current === 1 ? (
                                    <div className="content-step2">
                                        <div className="title">Confirm Withdrawal</div>
                                    </div>
                                ) : (
                                    <>
                                        {current === 2 ? (
                                            <div className="content-step5">
                                                <div className="title">Confirmation</div>
                                                <div className="des">Congratulations! Your tokens have withdrawn.</div>
                                                <div className="success-content">
                                                    <div className="img-success">
                                                        <img src="/images/staking/stake-success.png" alt="" />
                                                    </div>
                                                    <div className="mid-content">Success</div>
                                                    <div className="mid-content1">Congratulations! Your tokens have withdrawn</div>
                                                    <div className="mid-content2">You can check on Basescan to confirm the transaction.</div>
                                                    {isMobile ? (
                                                        <a className="mid-content3" target="_blank" rel="noreferrer" href={`${BASE_SCAN}/tx/${hashUnWr}`}>
                                                            Txn Hash: {hashUnWr.slice(0, 10)}...{hashUnWr.slice(-10)}
                                                        </a>
                                                    ) : (
                                                        <a className="mid-content3" target="_blank" rel="noreferrer" href={`${BASE_SCAN}/tx/${hashUnWr}`}>
                                                            Txn Hash: {hashUnWr.slice(0, 20)}...{hashUnWr.slice(-20)}
                                                        </a>
                                                    )}

                                                </div>
                                            </div>
                                        ) : (
                                            <>

                                                {/* {current === 3 ? (
                                                    <div className="content-step4">
                                                        <div className="title">Confirm</div>
                                                        <div className="des">Required transaction 2 of 2</div>
                                                        <div className="confirm-content">
                                                            <div className="cf-content1">In this step, you deposit the tokens into the staking contract.</div>
                                                            <div className="cf-content1">After this step, your tokens will be successfully staked.</div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {current === 4 ? (
                                                            <div className="content-step5">
                                                                <div className="title">Confirmation</div>
                                                                <div className="des">Congratulations! Your tokens are now staked.</div>
                                                                <div className="success-content">
                                                                    <div className="img-success">
                                                                        <img s/images/img/stake-success.png" alt="" />
                                                                    </div>
                                                                    <div className="mid-content">Success</div>
                                                                    <div className="mid-content1">Congratulations! Your tokens are now staked.</div>
                                                                    <div className="mid-content2">If desired, you may check BNB Smart Chain to confirm the transaction.</div>
                                                                    <a className="mid-content3" href="#">0x6db5d43a31599de3eb6804b64b9f6c2be670d52f02e1cf70248f720a1649fe5c</a>
                                                                </div>
                                                            </div>
                                                        ) : ('')}
                                                    </>
                                                )} */}
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <div className="steps-action">



                        {current > 0 && current !== steps.length - 1 && (
                            <button className="btn-control" onClick={() => prev()}>
                                <img src="/images/staking/icon-pre.png" alt="" />
                            </button>

                        )}
                        {current < steps.length - 1 && (
                            <>
                                {showNext ? (
                                    <>
                                        {current === 1 ?
                                            <>

                                                <button className="btn-control" onClick={handleWithdraw}>
                                                    <img src="/images/staking/icon-next.png" alt="" />
                                                </button>
                                            </>
                                            :
                                            <>
                                                <button className="btn-control" onClick={() => next()}>
                                                    <img src="/images/staking/icon-next.png" alt="" />
                                                </button>

                                            </>
                                        }
                                    </>
                                ) : (
                                    <button className="btn-control dis">
                                        <img src="/images/staking/icon-next.png" alt="" />
                                    </button>
                                )}
                            </>

                        )}
                        {current === steps.length - 1 && (
                            <button className="btn-control done" onClick={() => window.location.reload()}> Done</button>
                        )}

                    </div>
                </div>
                {!isMobile ? ('') : (
                    <>
                        <div className="line-status">
                            {/* @ts-ignore this lib is incompatible with react18  */}
                            <Steps current={current}>
                                <Step
                                    title={renderText1()}
                                    icon={renderImg1()}
                                />
                                <Step
                                    title={renderText2()}
                                    icon={renderImg2()}
                                />

                                <Step
                                    title={renderText5()}
                                    icon={renderImg5()}
                                />
                            </Steps>
                        </div>
                        <div className="line-mo"></div>
                    </>
                )}
                <Action
                    scStake={scStake}
                    tokenStake={tokenStake}
                    isSuccess={isSuccess}
                    tabBox={props.tabBox}
                    parentCallback1={callbackFunction1}
                    parentCallback={callbackFunction}
                    endTime={endTime}
                />

            </div>


            {/* @ts-ignore this lib is incompatible with react18  */}
            <Modal
                title="Withdrawal"
                visible={isModalOpen}
                footer={false}
                wrapClassName="modal-confirm"
            >
                <p className="modal-text1">Your request is being processed. Please wait...</p>
                <p className="spin-stake">
                    <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 55 }} spin />} />
                </p>

            </Modal>
        </div>


    )
}

export default Withdraw;