/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Button, Input, Pagination, Skeleton, Table, Tabs } from "antd";
import { GradientButton } from "../../../components/ui/GradientButton";
import { IconSearch } from "../../../assets/icons/tsx/icon-search";
import Faqs from "../staking/faqs";
import { useGetDashboardAffiliate } from "../../../query/useGetDashboardAffiliate";
import { chain, get } from "lodash";
import { Modal, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
    DashboardAffiliateTypes,
    ReferralCommissionTypes,
} from "../../../types/affiliate";
import { handleCopy } from "../../../utils/handleCopy";
import { useGetReferralCommission } from "../../../query/useGetReferralCommission";
import moment from "moment";
import Web3 from "web3";
import { renderAddressSplit } from "../../../utils/renderAddressSplit";
import abiClaimRef from "./utils/abiClaimRef.json";
import { RPC_BASE, SC_CLAIM_REF, WORK_CHAIN } from "../../../constants";
import { useContract } from "../../../hooks/useContract";
import { Noti } from "../../../components/Noti/toast";
import { useAccount } from "wagmi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { affiliateApi } from "../../../api/affiliate";
import { useHookStaked } from "../staking/storeStaked";

import "./style.css";
import { formatNumber } from "../staking/details/utils";
import CopyToClipboard from "react-copy-to-clipboard";

import { isMobile } from "react-device-detect";

const BENEFIT_ITEMS = [
    {
        title: "Partners",
        description: "Collaborate with the world’s most reliable RWA Platform",
    },
    {
        title: "Benificial",
        description:
            "We want you and your friends to benefit from joining our community",
    },
    {
        title: "Attractive rewards",
        description: "Get paid in MEY token",
    },
];

const STEPS_ITEMS = [
    {
        title: "Get a referral link",
        description:
            "Connect a wallet and generate your referral link in the Referral section.",
    },
    {
        title: "Invite friends",
        description: "Invite your friends to register via your referral link",
    },
    {
        title: "Earn Crypto",
        description: "Receive referral reward from your friends’ Launchpad Commit",
    },
];

const web3: any = new Web3(RPC_BASE);

const Affiliate = () => {
    const { data: resDashboard, isFetching } = useGetDashboardAffiliate();
    const { address } = useAccount();
    const dataDashboard: DashboardAffiliateTypes = useMemo(() => {
        return get(resDashboard, "data.data", {});
    }, [resDashboard]);

    const [amountClaimed1, setAmountClaimed1]: any = useState(0)
    const { selectedNetworkId }: any = useWeb3ModalState();

    const contractClaimRef: any = useContract(dataDashboard?.claimInfo?.contractClaim, abiClaimRef);
    const checkClaimed = async () => {
        try {
            await contractClaimRef.totalClaimed(address).then((res: any) => {
                let amount: any = web3.utils.fromWei(res, "ether");
                setAmountClaimed1(Number(amount))
            })
                .catch((err: any) => {
                    console.log('err', err)
                    setAmountClaimed1(0)
                })
        } catch (error) {
            console.log('error', error)
            setAmountClaimed1(0)
        }
    }

    useEffect(() => {
        if (address && contractClaimRef && selectedNetworkId === WORK_CHAIN) {
            checkClaimed()
        }
    }, [address, contractClaimRef, selectedNetworkId])


    const DASHBOARD_ITEMS = useMemo(() => {
        return [
            {
                title: "Total Referrals",
                value: dataDashboard?.totalReferrals || 0,
            },
            {
                title: "Total Reward",
                value: `${dataDashboard?.totalReward?.toFixed(2) || 0} MEY`,
            },
            {
                title: "Claim Reward",
                value: `${formatNumber((dataDashboard?.claimInfo?.totalReward - amountClaimed1 || 0), 2)} MEY`,
            },

        ];
    }, [dataDashboard, amountClaimed1]);

    const [current, setCurrent] = useState(1)
    const [pageSize, setPageSize] = useState(5)
    const [totalItem, setTotalItem] = useState(0)

    const onChange = (page: any) => {
        setCurrent(page);
    };

    const [keyTabs, setKeyTabs] = useState(1)
    const onChangeTabs = async (key: any) => {
        setKeyTabs(key)
    }

    const [listHis, setListHis]: any = useState([])
    const getHisClaim = async () => {
        try {
            let param = {
                ownerAddress: address,
                pageSize: pageSize,
                pageIndex: current - 1,
            }
            let res = await affiliateApi.getHisRef(param);
            setListHis(res?.data?.data)
            setTotalItem(res?.data?.totalCount)
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        if (address) {
            getHisClaim()
        }
    }, [address, current])



    const columns = [
        {
            title: "Date",
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: "Type",
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: "Total Amount",
            dataIndex: 'amount',
            key: 'amount',
            render: (record: any) => (
                <div className="font-radioCanada text-sm font-medium">
                    {record}  MEY
                    {/* {record?.type} */}
                </div>
            ),
        },
        {
            title: "TxID",
            dataIndex: 'txHash',
            key: 'txHash',
            render: (record: any) => (
                <div className="font-radioCanada text-sm font-medium">
                    {renderAddressSplit(record)}
                </div>
            ),
        },
        {
            title: "Status",
            dataIndex: 'status',
            key: 'status',
            // render: (_: string, record: ReferralCommissionTypes) => (
            //   <div className="font-radioCanada text-sm font-medium">
            //     Successful
            //   </div>
            // ),
        },
    ];

    return (
        <div className={`affiliate ${isMobile ? 'py-6 px-4' : 'py-4 px-6'} font-radioCanada`}>
            {/*Banner*/}
            {/* <div className="flex justify-between items-center bg-[#0E43DE] rounded-[16px] pr-[120px] pl-6">
                <div className="flex flex-col gap-y-1.5 max-w-[550px]">
                    <div className="text-white font-semibold text-[36px] text-start">
                        Invite your friends Earn{" "}
                        <span className="text-secondary">MEY</span> together
                    </div>
                    <div className="text-xl text-white text-start">
                        Earn up to 6% from friends staking on MeyFi platform, Refer friends
                        to stake with the maximum reward.
                    </div>
                </div>
                <img
                    width={338}
                    height={225}
                    src="/images/affiliate/banner-img.png"
                    alt=""
                />
            </div> */}
            {isMobile && <p className="title">Mey Affliate</p>}
            <div className="banner">
                <img width={'100%'} src={`/images/affiliate/banner-${isMobile ? 'mobile' : 'desktop'}.png`} />
            </div>
            {/*Benefit*/}
            <div className="lg:mt-6 my-6">
                <div className='lg:text-[32px] text-[24px] font-medium text-secondary'>
                    Mey Affiliate Benefits
                </div>
                <div className="flex items-center justify-center">
                    <div className="lg:my-4 mt-4 max-w-[638px] text-white">
                        We are looking for key option leaders (KOLs) and content cretors who share our mission and values to promote our RWA platform.
                    </div>
                </div>
                <div className="lg:p-4 grid lg:grid-cols-3">
                    {BENEFIT_ITEMS.map(({ title, description }, index) => (
                        <BenefitItems
                            title={title}
                            description={description}
                            index={index}
                        />
                    ))}
                </div>
            </div>
            {/*Referral*/}
            <div className="lg:p-4 lg:rounded-[24px] lg:border border-[#3B3F45] flex flex-col lg:gap-y-6 gap-y-4">
                <div className="grid lg:grid-cols-3 gap-x-5 gap-y-5">
                    <div className="lg:col-span-1 backdrop-blur-[25px] lg:p-6 p-4 rounded-[16px] border border-[rgba(59,63,69,0.4)] bg-[rgba(59,63,69,0.4)] flex flex-col gap-y-4">
                        <div className="text-white font-medium text-start">
                            Referral Code
                        </div>
                        {isFetching ? (
                            <Skeleton.Input active size="large" block />
                        ) : (
                            <Input
                                value={renderAddressSplit(dataDashboard?.referralCode)}
                                readOnly
                                suffix={
                                    <div
                                        onClick={() => handleCopy(dataDashboard?.referralCode)}
                                        className="hover:cursor-pointer text-primary text-[14px] font-medium"
                                    >
                                        Copy
                                    </div>
                                }
                                className="w-full !bg-black text-white font-medium h-12 p-2.5 rounded-[12px] border-transparent input-ref"
                            />
                        )}
                    </div>
                    <div className="lg:col-span-2 backdrop-blur-[25px] lg:p-6 p-4 rounded-[16px] border border-[rgba(59,63,69,0.4)] bg-[rgba(59,63,69,0.4)] flex flex-col gap-y-4">
                        <div className="text-white font-medium text-start">
                            Referral Link
                        </div>
                        {isFetching ? (
                            <Skeleton.Input active size="large" block />
                        ) : (
                            <div className="flex flex-col lg:flex-row gap-y-2.5 gap-x-2.5">
                                <Input
                                    value={renderAddressSplit(dataDashboard?.referralLink)}
                                    readOnly
                                    suffix={
                                        <div
                                            onClick={() => handleCopy(dataDashboard?.referralLink)}
                                            className="hover:cursor-pointer text-primary text-[14px] font-medium"
                                        >
                                            Copy
                                        </div>
                                    }
                                    className="w-full !bg-black text-white font-medium h-12 p-2.5 rounded-[12px] border-transparent grow"
                                />
                                <GradientButton className="shrink-0 m-auto lg:m-0 w-[200px] h-12">
                                    <div className="text-white">Invite Friends</div>
                                </GradientButton>
                            </div>
                        )}
                    </div>
                </div>
                <div className="text-white font-medium text-2xl text-start">
                    Dashboard
                </div>
                <div className="backdrop-blur-[25px] lg:p-6 p-4 rounded-[16px] border border-[rgba(59,63,69,0.4)] bg-[rgba(59,63,69,0.4)] grid lg:grid-cols-3 gap-x-6 gap-y-6">
                    {DASHBOARD_ITEMS.map(({ title, value }, index) => (
                        <DashboardItems
                            isLoading={isFetching}
                            title={title}
                            value={value}
                            index={index}
                            data={dataDashboard?.claimInfo}
                        />
                    ))}
                </div>
                <div className="flex justify-between items-center">
                    {/* <div className="text-white font-medium text-2xl text-start">
            Referral Commission
          </div> */}
                    {/* <div className="flex gap-x-2.5 items-center">
                        <Input
                            placeholder="Search Name"
                            className="w-[274px] h-10 !bg-[rgba(59,63,69,0.4)] border-transparent text-white focus:bg-black placeholder:!text-white search-input"
                            prefix={<IconSearch />}
                        />
                        <Button className="w-[100px] h-[38px] !bg-black text-white border border-[#3B3F45]">
                            This week
                        </Button>
                        <Button className="w-[100px] h-[38px] !bg-black text-white border border-[#3B3F45]">
                            All Stats
                        </Button>
                    </div> */}
                </div>
                <div className="tabs-list cus">
                    <Tabs defaultActiveKey="0" onChange={onChangeTabs}>
                        <Tabs.TabPane tab={tabRef} key="0">
                            <div className="p-3 rounded-[12px] bg-[#1A1C1E]">
                                <TableReferralCommission />
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={tabHistory} key="1">
                            <div className="p-3 rounded-[12px] bg-[#1A1C1E]">
                                <Table
                                    dataSource={listHis}
                                    // loading={isFetching}
                                    id="table-referral"
                                    size="small"
                                    columns={columns}
                                    pagination={false}
                                    scroll={{ x: 'max-content' }}
                                />
                                <div className="pagination-his">
                                    <Pagination
                                        current={current}
                                        defaultPageSize={pageSize}
                                        onChange={onChange}
                                        total={totalItem}
                                    />
                                </div>
                            </div>
                        </Tabs.TabPane>
                    </Tabs>
                </div>

            </div>
            {/*How It Work*/}
            <div className="flex justify-between items-center mt-[60px]">
                <div className="text-white font-medium text-2xl text-start">
                    How it work?
                </div>
                <div className="text-primary font-medium hover:cursor-pointer">
                    Reward Rules?
                </div>
            </div>
            <div className="mt-6 grid lg:grid-cols-3 lg:gap-x-5 gap-y-5">
                {STEPS_ITEMS.map(({ title, description }, index) => (
                    <StepItems
                        title={title}
                        description={description}
                        key={index}
                        index={index}
                    />
                ))}
            </div>
            <Faqs />
        </div>
    );
};

const BenefitItems = ({
    title,
    description,
    index,
}: {
    title: string;
    description: string;
    index: number;
}) => {
    return (
        <div className="flex gap-x-4 items-center p-6">
            <img alt="" src={`/images/affiliate/benefit-${index + 1}.png`} />
            <div className="flex flex-col gap-y-[2.5] text-start max-w-[285px]">
                <div className="font-medium text-white">{title}</div>
                <div className="text-white/60 text-[14px]">{description}</div>
            </div>
        </div>
    );
};

const DashboardItems = ({
    title,
    value,
    index,
    isLoading,
    data
}: {
    title: string;
    value: string | number;
    index: number;
    isLoading: boolean;
    data: any
}) => {

    const { address } = useAccount();
    const { selectedNetworkId }: any = useWeb3ModalState();
    const [isModalOpen, setIsModalOpen]: any = useState(false)
    const [isClaimed, setIsClaimed]: any = useState(false)
    const [amountClaimed, setAmountClaimed]: any = useState(0)
    const [state, actions] = useHookStaked()

    const contractClaimRef: any = useContract(data?.contractClaim, abiClaimRef);

    const getInfoClaim = async () => {
        try {
            let param = {
                ownerAddress: address
            }
            let res = await affiliateApi.getClaimRef(param);
            return res?.data?.data
        } catch (error) {
            console.log("Error: ", error);
        }
    };



    const saveLog = async (hash: any, amount: any, type: any) => {
        try {
            let params = {
                ownerAddress: address,
                contractAddress: data?.contractClaim,
                txHash: hash,
                amount: amount,
                type: type,
                assetToken: 'Mey',
            }
            await actions.addHistory(params)
        } catch (error) {

        }
    }

    const handleClaimRef = async () => {
        try {
            setIsModalOpen(true)
            let dataClaim = await getInfoClaim()
            if (dataClaim && dataClaim !== null) {
                let amountTemp = web3.utils.toWei(dataClaim?.rewardClaim?.toString(), "ether")
                let args = [amountTemp, dataClaim?.signClaim];
                let gas = await contractClaimRef.estimateGas.claimTokens(...args);
                await contractClaimRef.claimTokens(...args, { gasLimit: gas })
                    .then((res: any) => {
                        saveLog(res?.hash, dataClaim?.rewardClaim, 12)
                        res.wait()
                            .then(async (resWait: any) => {
                                if (resWait?.status === 1) {
                                    Noti('success', "Claim successfully", 3000)
                                    setIsModalOpen(false)
                                    setIsClaimed(true)
                                    setTimeout(() => {
                                        window.location.reload()
                                    }, 1000);
                                } else {
                                    Noti('error', "Unknown error (status hash fail)", 3000)
                                    setIsModalOpen(false)
                                }
                            })
                            .catch((err: any) => {
                                Noti('error', err?.reason || err?.message || 'Unknown error', 3000)
                                console.log('err', err)
                                setIsModalOpen(false)
                            })
                    })
                    .catch((err: any) => {
                        Noti('error', err?.reason || err?.message || 'Unknown error', 3000)
                        console.log('err', err)
                        setIsModalOpen(false)
                    })
            } else {
                Noti('error', "Unknown error (api fail)", 3000)
                setIsModalOpen(false)
            }
        } catch (error: any) {
            console.log('error', error)
            Noti('error', error?.reason || error?.message || 'Unknown error', 3000)
            setIsModalOpen(false)
        }
    }

    const checkClaimed = async () => {
        try {
            await contractClaimRef.totalClaimed(address).then((res: any) => {
                let amount: any = web3.utils.fromWei(res, "ether");
                setAmountClaimed(Number(amount))
            })
                .catch((err: any) => {
                    console.log('err', err)
                    setAmountClaimed(0)
                })
        } catch (error) {
            console.log('error', error)
            setAmountClaimed(0)
        }
    }

    useEffect(() => {
        if (address && contractClaimRef && selectedNetworkId === WORK_CHAIN) {
            checkClaimed()
        }
    }, [address, contractClaimRef, selectedNetworkId])

    const btnClaimRef = () => {
        const obj = {
            className: "gradient-button shrink-0 w-[90px] h-12 btn-cus",
            disabled: isClaimed || isModalOpen
                || (data?.totalReward - amountClaimed) < data?.minClaimReward
        };
        return obj;
    };
    const btnClaimRefDis = () => {
        const obj = {
            className: "gradient-button shrink-0 w-[90px] h-12 btn-cus",
            disabled: true
        };
        return obj;
    };

    return (
        <>
            <div className="flex items-center lg:px-6 gap-x-4">
                <img alt="" src={`/images/affiliate/dashboard-${index + 1}.png`} />
                <div className="flex flex-col gap-y-1 text-start">
                    <div className="text-[#707A8A]">{title}</div>
                    {isLoading ? (
                        <Skeleton.Button active></Skeleton.Button>
                    ) : (
                        <div className="text-white font-medium text-2xl">{value}</div>
                    )}
                </div>
                {address ? (
                    <>
                        {index === 2 ? (
                            <>
                                {/* {data?.rewardClaim > 0 ? ( */}
                                <>
                                    {isClaimed ? (
                                        <button {...btnClaimRefDis()}
                                        >
                                            <div
                                                className="text-white-cus"
                                            >
                                                Claimed
                                            </div>
                                        </button>
                                    ) : (
                                        <>
                                            <button {...btnClaimRef()}
                                            >
                                                <div
                                                    className="text-white"
                                                    onClick={handleClaimRef}
                                                >
                                                    Claim
                                                </div>
                                            </button>
                                        </>
                                    )}
                                </>
                                {/* ) : ('')} */}
                            </>

                        ) : ('')}
                    </>
                ) : ('')}


            </div>

            {/* @ts-ignore this lib is incompatible with react18  */}
            <Modal
                title="Claim reward"
                visible={isModalOpen}
                // onOk={handleOk}
                // onCancel={handleCancel}
                footer={false}
                wrapClassName="modal-confirm"
            >
                <p className="spin-stake">
                    <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 60 }} spin />} />
                </p>
                <p className="modal-text1">Your request is being processed. Please wait...</p>


            </Modal>
        </>
    );
};

const StepItems = ({
    title,
    description,
    index,
}: {
    title: string;
    description: string;
    index: number;
}) => {
    return (
        <div className="p-6 bg-[rgba(59,63,69,0.4)] backdrop-blur-[24px] rounded-[16px] flex flex-col gap-y-4 text-start transition hover:shadow-[0_0_16px_0_#54FFFF] hover:cursor-pointer">
            <img
                width={46}
                height={46}
                alt=""
                src={`/images/affiliate/step-${index + 1}.png`}
            />
            <div className="font-medium text-xl text-white">{title}</div>
            <div className="text-[14px] text-white/60">{description}</div>
        </div>
    );
};

const TableReferralCommission = () => {
    const { data, isFetching } = useGetReferralCommission({ pageIndex: 0 });
    const dataReferralCommission: ReferralCommissionTypes[] = useMemo(() => {
        return get(data, "data.data", []);
    }, [data]);

    const columns = [
        {
            title: "Friend’s Account",
            render: (_: string, record: ReferralCommissionTypes) => (
                <div className="font-radioCanada text-sm font-medium">
                    {renderAddressSplit(record?.fromAddress)}
                </div>
            ),
        },
        {
            title: "Commission Time",
            render: (_: string, record: ReferralCommissionTypes) => (
                <div className="font-radioCanada text-sm font-medium">
                    {/* {moment(record?.commissionTime).format("YYYY/MM/DD-HH:mm:ss")} */}
                    {record?.commissionTime}
                </div>
            ),
        },
        {
            title: "Account Type",
            render: (_: string, record: ReferralCommissionTypes) => (
                <div className="font-radioCanada text-sm font-medium">
                    {record?.type}
                </div>
            ),
        },
        {
            title: "Commission Rate",
            render: (_: string, record: ReferralCommissionTypes) => (
                <div className="font-radioCanada text-sm font-medium">
                    {record?.commissionRate}
                </div>
            ),
        },
        {
            title: "Commission Amount",
            render: (_: string, record: ReferralCommissionTypes) => (
                <div className="font-radioCanada text-sm font-medium">
                    {record?.commissionAmount} MEY
                </div>
            ),
        },
    ];
    return (
        <Table
            dataSource={dataReferralCommission}
            loading={isFetching}
            id="table-referral"
            size="small"
            columns={columns}
            scroll={{ x: 'max-content' }}
        />
    );
};

const tabRef = (
    <>
        <div className="title-tabs">
            Referral Comission
        </div>
    </>
)
const tabHistory = (
    <>
        <div className="title-tabs">
            History
        </div>
    </>
)

export default Affiliate;
