import clsx from "clsx";

interface GradientButtonProps {
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
  isDisabled?: boolean;
}

export const GradientButton = ({
  className,
  onClick,
  children,
  isDisabled,
}: GradientButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={clsx("gradient-button", className, isDisabled && "dis")}
    >
      {children}
    </button>
  );
};
