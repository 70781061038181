import React from "react";
import { useWeb3Modal } from '@web3modal/wagmi/react'
import "./style.scss"

const EmptyConnect = () => {
    const { open } = useWeb3Modal()
    const handleConnect = () => {
        open();
    }

    return (
        <>
            <div className="box-empty-connect">
                <div className="box-img">
                    <img src="/images/not-connect.png" alt="" />
                </div>
                <div className="title">
                    Connect your wallet
                </div>
                <div className="desc">
                    Please connect your wallet to see your account information
                </div>
                <div className="connect-wallet">
                    <button className="btn btn-connect" onClick={handleConnect}>
                        Connect Wallet
                    </button>
                </div>
            </div>
        </>
    )
}
export default EmptyConnect