
import { useEffect } from 'react';
import './style.css'

const Policy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="container-policy">
                <div className="content-policy">
                    <div className="title1-policy">
                        Privacy Policy
                    </div>
                    <div className="dec-policy">
                        This Privacy Policy ("Policy") describes how MeyNetwork ("MeyNetwork," "we," "us" or "our") may collect, use and disclose information, and your choices regarding this information. Please read this Policy carefully and contact us with questions at social@mey.network.
                    </div>
                    <br />
                    <div className="title2-policy">
                        Applicability of This Policy
                    </div>
                    <br />
                    <br />
                    <div className="dec-policy">
                        This Policy applies to our services, which include the services we provide on our platform, the user interface to the Protocol, or any other websites, pages, features, mobile applications, or content we own or operate (collectively, the "Sites") or when you use any MeyNetwork API or third party applications relying on such an API, and related services (collectively, the "Services"). If you do not agree with the terms of this Policy, do not access or use the Services, Sites, or any other aspect of our business.
                    </div>
                    <br />
                    <div className="title2-policy">
                        What We Collect
                    </div>
                    <br />
                    <br />
                    <div className="dec-policy">
                        When you interact with our Services, we may collect:
                    </div>
                    <ul>
                        <li className="dec-policy">
                            Contact Information, such as your email address.
                        </li>
                        <li className="dec-policy">
                            Financial Information, such as your Base address, cryptocurrency wallet information, transaction history, and associated fees paid.
                        </li>
                        <li className="dec-policy">
                            Transaction Information, such as information about the transactions you make on our Services, such as the type of transaction, transaction amount, and timestamp.
                        </li>
                        <li className="dec-policy">
                            Correspondence, such as your feedback, questionnaire, and other survey responses, and information you provide to our support teams, including via our help chat.
                        </li>
                        <li className="dec-policy">
                            Online Identifiers, such as geo-location/tracking details, browser fingerprint, operating system, browser name and version, and/or personal IP addresses.
                        </li>
                        <li className="dec-policy">
                            Usage Data, such as user preferences and other data collected via cookies and similar technologies.
                        </li>
                        <li className="dec-policy">
                            Information We Get from Others. We may get information about you from other sources as required or permitted by applicable law, including public databases, credit bureaus & ID verification partners. We may combine the information collected from these sources with the information we get from this Site in order to comply with our legal obligations and limit the use of our Services in connection with fraudulent or other illicit activities.
                        </li>
                        <li className="dec-policy">
                            Information from cookies and other tracking technologies. We, and third parties we authorize, may Sites and mobile apps, and collect information about the use of the Services, as well as about our interactions with you. This information may include internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, clickstream data, and information about your interactions with the communications we send to you. We may combine this automatically collected log information with other information we collect about you. You may choose to set your web browser to refuse cookies or to alert you when cookies are being sent. If you do so, please note that some parts of our Services may not function properly. How We Use Information We use your information under your instructions, including any applicable terms in the Terms of Use, and as required by applicable law. We may also use the information we collect for:
                        </li>
                    </ul>
                    <br />
                    <div className="title2-policy">
                        Providing Services and Features
                    </div>
                    <br />
                    <br />
                    <div className="dec-policy">
                        We may use the information we collect to provide, personalize, maintain, and improve our products and Services, including as we described in the Terms of Use. This includes using information to:
                    </div>
                    <ul>
                        <li className="dec-policy">
                            operate, maintain, customize, measure, and improve our Services, and manage our business;
                        </li>
                        <li className="dec-policy">
                            process transactions;
                        </li>
                        <li className="dec-policy">
                            send information, including confirmations, notices, updates, security alerts, and support and administrative messages; and
                        </li>
                        <li className="dec-policy">
                            to create de-identified or aggregated data.

                        </li>
                    </ul>
                    <br />
                    <div className="title2-policy">
                        Safety and Security
                    </div>
                    <br />
                    <br />
                    <div className="dec-policy">
                        We may use your information to help maintain the safety, security, and integrity of you and our Services, including to:
                    </div>
                    <br />

                    <ul>
                        <li className="dec-policy">
                            protect, investigate, and deter against fraudulent, unauthorized, or illegal activity;
                        </li>
                        <li className="dec-policy">
                            monitor and verify identity or service access, combat spam, malware or security risks;

                        </li>
                        <li className="dec-policy">
                            perform internal operations necessary to provide our Services, including to troubleshoot software bugs and operational problems;

                        </li>
                        <li className="dec-policy">
                            enforce our agreements with third parties, and address violations of our Terms of Use or agreements for other Services; and
                        </li>
                        <li className="dec-policy">
                            comply with applicable security laws and regulations.
                        </li>
                    </ul>
                    <br />
                    <div className="title2-policy">
                        User Support
                    </div>
                    <br />
                    <br />
                    <div className="dec-policy">
                        We may use the information we collect to provide support, including to:
                    </div>
                    <br />
                    <ul>
                        <li className="dec-policy">
                            direct questions to the appropriate support person;

                        </li>
                        <li className="dec-policy">
                            investigate and address user concerns; and

                        </li>
                        <li className="dec-policy">
                            monitor and improve our customer support responses and processes.

                        </li>
                    </ul>
                    <br />
                    <div className="title2-policy">
                        Research and Development

                    </div>
                    <br />
                    <br />
                    <div className="dec-policy">
                        We may use the information we collect for testing, research, analysis, and product development to improve your experience. This helps us to improve and enhance the safety and security of our Services, improve our ability to prevent the use of our Services for illegal or improper purposes, and develop new features and products relating to our Services.
                    </div>
                    <br />
                    <div className="title2-policy">
                        Legal and Regulatory Compliance
                    </div>
                    <br />
                    <br />
                    <div className="dec-policy">
                        We may verify your identity by comparing the personal information you provide against third-party databases and public records. We may use the information we collect to investigate or address claims or disputes relating to the use of our Services, or as otherwise allowed by applicable law, or as requested by regulators, government entities, and official inquiries.
                    </div>
                    <br />
                    <div className="title2-policy">
                        Direct Marketing
                    </div>
                    <br />
                    <br />
                    <div className="dec-policy">
                        We may use the information we collect to market our Services to you. This may include sending you communications about our Services, features, promotions, surveys, news, updates, and events, and managing your participation in these promotions and events. If you do not want us to send you marketing communications, please opt-out by selecting "unsubscribe" to any marketing email sent by us or by contacting us at social@mey.network.
                    </div>
                    <br />
                    <div className="title2-policy">
                        How We Share & Disclose Information
                    </div>
                    <br />
                    <br />
                    <div className="dec-policy">
                        We may share your information in the following circumstances:
                    </div>
                    <br />
                    <div className="dec-policy cus">
                        With Your Consent. For example, you may let us share personal information with others for their own marketing uses. Those uses will be subject to their privacy policies.
                        To Comply with Our Legal Obligations. We may share your information: (A) to cooperate with government or regulatory investigations; (B) when we are compelled to do so by a subpoena, court order, or similar legal procedure; (C) when we believe in good faith that the disclosure of personal information is necessary to prevent harm to another person; (D) to report suspected illegal activity; or (E) to investigate violations of our User Agreement or any other applicable policies.
                        With Service Providers. We may share your information with service providers who help facilitate business and compliance operations such as marketing and technology services. Our contracts require these service providers to only use your information in connection with the services they perform for us.
                        During a Change to Our Business. If we engage in a merger, acquisition, bankruptcy, dissolution, reorganization, sale of some or all of our assets or stock, financing, public offering of securities, acquisition of all or a portion of our business, a similar transaction or proceeding, or steps in contemplation of such activities, some or all of your information may be shared or transferred, subject to standard confidentiality arrangements.
                        Aggregated or De-identified Data. We may share aggregated and/or anonymized data with others for their own uses.
                    </div>
                    <br />
                    <div className="title2-policy">
                        Data Retention
                    </div>
                    <br />
                    <br />
                    <div className="dec-policy">
                        To view or update your information, contact us at social@mey.network. We store your information throughout the life of your use of the Protocol and retain your information for a minimum of five years to comply with our legal obligations or to resolve disputes. If you cease using the Protocol, we will neither use your information for any further purposes, nor sell or share your information with third parties, except as necessary to prevent fraud and assist law enforcement, as required by law, or in accordance with this Policy.
                    </div>
                    <br />
                    <div className="title2-policy">
                        Security
                    </div>
                    <br />
                    <br />
                    <div className="dec-policy">
                        We maintain administrative, technical, and physical safeguards designed to protect the personal information we maintain against unauthorized access or disclosure. No system can be completely secure. Therefore, although we take steps to secure your information, we cannot guarantee that your information, searches, or other communication will always remain secure. You are responsible for all activity on the Protocol relating to any of your Base network addresses and/or cryptocurrency wallets.
                    </div>
                    <br />
                    <div className="title2-policy">
                        Age Limitations
                    </div>
                    <br />
                    <br />
                    <div className="dec-policy">
                        To the extent prohibited by applicable law, we do not allow the use of our Services and Sites by anyone younger than the legal age in the jurisdiction in which the user resides. If you learn that anyone younger than the legal age has unlawfully provided us with personal data, please contact us at social@mey.network and we will take steps to delete such information, close any such accounts, and, to the extent possible, prevent the user from continuing to use our Services.
                    </div>
                    <br />
                    <div className="title2-policy">
                        Changes to This Policy
                    </div>
                    <br />
                    <br />
                    <div className="dec-policy">
                        If we make any changes, we will change the Last Updated date above. We encourage you to review this Policy to stay informed. If we make material changes, we will provide additional notice, such as via the email specified in your account or through the Services or Sites.
                    </div>
                    <br />
                    <div className="title2-policy">
                        Online Tracking Opt-out Guide

                    </div>
                    <br />
                    <br />
                    <div className="dec-policy">
                        Like many companies online, we use services provided by Google and other companies that use tracking technology. These services rely on tracking technologies such as cookies and web beacons to collect directly from your device information about your browsing activities, your interactions with websites, and the device you are using to connect to the Internet. There are several ways to opt out of having your online activity and device data collected through these services, which we have summarized below:
                    </div>
                    <br />
                    <br />
                    <div className="dec-policy cus">
                        Blocking cookies in your browser. Most browsers let you remove or reject cookies, including cookies used for interest-based advertising. To do this, follow the instructions in your browser settings. Many browsers accept cookies by default until you change your settings. For more information about cookies, including how to see what cookies have been set on your device and how to manage and delete them, visit www.allaboutcookies.org.
                    </div>
                    <br />
                    <div className="dec-policy cus">
                        Blocking advertising ID use in your mobile settings. Your mobile device settings may provide functionality to limit the use of the advertising ID associated with your mobile device for interest-based advertising purposes. Using privacy plug-ins or browsers. You can block our websites from setting cookies used for interest-based ads by using a browser with privacy features.
                    </div>
                    <br />
                    <div className="dec-policy cus">
                        Platform opt-outs. The following advertising partner offers opt-out features that let you opt out of use of your information for interest-based advertising - Google: https://adssettings.google.com. Advertising industry opt-out tools. You can also use the opt-out options set forth below to limit the use of your information for interest-based advertising by participating companies. Note that because these opt-out mechanisms are specific to the device or browser on which they are exercised, you will need to opt out on every browser and device you use. Digital Advertising Alliance: http://optout.aboutads.info Network Advertising Initiative: http://optout.networkadvertising.org/
                    </div>
                    <br />
                    {/* <div className="title2-policy">
                        Additional Disclosure for Our Consumers and Customers
                    </div>
                    <br />
                    <div className="dec-policy">
                        This Additional Disclosure governs our collection, use, and sharing of personal information that users provide us to initiate or complete the interaction process with the Protocol. To the extent there are conflicting provisions between this Additional Disclosure and other sections of the Privacy Policy, the Additional Disclosure will govern.
                    </div>
                    <br />
                    <div className="dec-policy cus">
                        Privacy Practices. We do not “sell” personal information as defined under the CCPA. Please review the “Sharing and Disclosure of Information” section above for further details about the categories of parties with whom we share information.

                    </div>
                    <br />
                    <div className="dec-policy cus">
                        Privacy Rights. The CCPA gives individuals the right to request information about how we have collected, used, and shared your personal information. It also gives you the right to request a copy of any information we may maintain about you. You may also ask us to delete any personal information that we may have received about you. Please note that the CCPA limits these rights, for example, by prohibiting us from providing certain sensitive information in response to access requests and limiting the circumstances under which we must comply with a deletion request. We will respond to requests for information, access, and deletion only to the extent we are able to associate, with a reasonable effort, the information we maintain with the identifying details you provide in your request. If we deny the request, we will communicate the decision to you. You are entitled to exercise the rights described above free from discrimination.
                        Submitting a Request. You can submit a request for information, access, or deletion to social@mey.network.
                        Identity Verification. The CCPA requires us to collect and verify the identity of any individual submitting a request to access or delete personal information before providing a substantive response.
                        Authorized Agents. California residents can designate an “authorized agent” to submit requests on their behalf. We will require the authorized agent to have a written authorization confirming their authority.

                    </div>
                    <br /> */}
                    <div className="title2-policy">
                        Additional Disclosure for Our Consumers and Customers
                    </div>
                    <br />
                    <br />
                    <div className="dec-policy">
                        This Additional Disclosure governs our collection, use, and sharing of personal information that users provide to us to initiate or complete the process of interacting with the Protocol. To the extent there are conflicting provisions between this Additional Disclosure and other sections of the Privacy Policy, the Additional Disclosure will govern.
                    </div>
                    <br />
                    <br />
                    <div className="dec-policy">
                        The types of personal information we collect and share can include:
                    </div>
                    <br />
                    <br />
                    <div className="dec-policy cus">
                        Contact details
                    </div>
                    <div className="dec-policy cus">
                        Transaction history
                    </div>
                    <div className="dec-policy cus">
                        Cryptocurrency balances and wallets When you are no longer our customer, we continue to share your information as described in this notice.
                    </div>
                    <br />
                    <br />
                    <div className="title2-policy">
                        Reasons We Can Share Your Personal Information
                    </div>
                    <br />
                    <br />
                    <div className="dec-policy">
                        We need to share users' personal information to operate certain aspects of the Protocol and our business. Below, whether we share your personal information, the reasons we share your personal information, and whether you can limit this sharing
                    </div>
                    <br />
                    <br />
                    <div className="dec-policy cus">
                        We share users' personal information for our everyday business purposes, such as processing and matching your orders and responding to court orders and legal investigations. You cannot limit our sharing of this information.
                    </div>
                    <div className="dec-policy cus">
                        We share users' personal information for our marketing purposes, such as to offer our products and services to you. You cannot limit our sharing of this information.
                    </div>
                    <div className="dec-policy cus">
                        We do not share users' personal information for joint marketing with financial companies.

                    </div>
                    <div className="dec-policy cus">
                        We do not share users' personal information for our affiliates' everyday business purposes.

                    </div>
                    <div className="dec-policy cus">
                        We do not share users' personal information for our affiliates to market to you.

                    </div>
                    <div className="dec-policy cus">
                        We do not share users' personal information for nonaffiliates to market to you.

                    </div>
                    <br />
                    <br />
                    <div className="dec-policy">
                        "Affiliates" refer to companies related by common ownership or control; "nonaffiliates" refer to companies not related by common ownership or control; and "joint marketing" refers to a formal agreement between nonaffiliated financial companies that together market financial products or services to you.

                    </div>
                    <br />
                    <div className="title2-policy">
                        How Does MeyNetwork Protect My Personal Information?

                    </div>
                    <br />
                    <br />
                    <div className="dec-policy">
                        We use security measures that comply with applicable law to protect your personal information from unauthorized access and use. These measures include computer safeguards and secured files and buildings.

                    </div>
                    <br />
                    <div className="title2-policy">
                        How Does MeyNetwork Collect My Personal Information?
                    </div>
                    <br />
                    <br />
                    <div className="dec-policy">
                        We collect your personal information, for example, when you deposit Digital Assets in the Protocol, make transactions using the Protocol, or withdraw Digital Assets from the Protocol. We may also collect your personal information from other companies.

                    </div>
                    <br />
                    <div className="title2-policy">
                        Contact Us
                    </div>
                    <br />
                    <br />
                    <div className="dec-policy">
                        Please contact us if you have any questions about this Policy or seek to exercise any of your statutory rights. Subject to applicable laws, we will respond within a reasonable timeframe. You may contact us at social@mey.network.
                    </div>
                </div>
            </div>
        </>
    )
}

export default Policy;