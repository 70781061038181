import React from "react";
import { Col, Collapse, Row } from 'antd';

const Faqs = () => {

    const { Panel } = Collapse;

    return (
        <>
            <div className="collapse-faq">
                <div className="title">
                    Frequently Asked Questions
                </div>
                <div className="all-collapse">
                    <div className="collapse1">
                        <Collapse accordion>
                            <Panel header="What tokens are used as rewards?" key="1">
                                <p>
                                    Users will be able to stake their MEY tokens and receive rewards in MEY.
                                </p>
                            </Panel>
                            <Panel header="Does the lock term period of 30,60, and 90 days restart after each additional stake?" key="2">
                                <p>
                                    Yes. For example, today you staked 100 MEY in a pool with a lockup period of 60 days. Immediately after staking, the timer starts for 60 days, when you can harvest profits without any fees. In 10 days, you decide to stake another 200 MEY in the same pool. During this staking period, the timer will restart and start counting again for 60 days. With this action, Your rewards will remain in the balance during this period.
                                </p>
                            </Panel>
                            <Panel header="Can I unstake the total or partial amount from the pool?" key="3">
                                <p>
                                    You can unstake partial or all of your total amount of staked tokens. When you unstake as not an early withdrawal, you will receive the total amount of staked tokens + the rewards earned.
                                </p>
                            </Panel>
                            {/* <Panel header="How much time does it take to make Unstake?" key="4">
                                <p>
                                    Stake token will be locked for 72 hours. After 72 hours, you can start Unstake, the amount of unstake is arbitrary.
                                </p>
                            </Panel> */}
                            <Panel header="How much time does it take to make Withdraw?" key="5">
                                <p>
                                    Unstake token will be locked for 7 days before returning the token.
                                </p>
                            </Panel>
                            <Panel header="When can I receive the rewards?" key="6">
                                <p>
                                    Rewards can be claimed at any time and without waiting, returned to the user's wallet. Rewards can be used to continue staking or for other purposes.
                                </p>
                            </Panel>
                            <Panel header="Do Fixed Staking pools continue to function after the 30/60/90 days of lock term?" key="7">
                                <p>
                                    Yes. Fixed Staking pools are dateless and keep functioning after the end of the lock term. The lock term identifies the number of days after which the fee for early withdrawal won’t be applied.
                                </p>
                            </Panel>
                            <Panel header="What is Fixed Staking on MeyFi?" key="8">
                                <p>
                                    Fixed Staking allows you to stake a token and receive profit with a fixed APR in the same token. For example, Stake MEY - Earn MEY.
                                </p>
                            </Panel>

                        </Collapse>
                    </div>
                    <div className="collapse2">
                        <Collapse accordion>
                            <Panel header="What Fixed Staking pools are available on MeyFi?" key="9">
                                <p>
                                    Currently, there are pools with the tokens: MEY. More pools will be added in the future.                        </p>
                            </Panel>
                            <Panel header="Is there any impermanent loss in Fixed Staking?" key="10">
                                <p>
                                    There is no impermanent loss. You can maximize your crypto funds safely and simply.                        </p>
                            </Panel>
                            <Panel header="Is there any fee for early withdrawal from the pools?" key="11">
                                <p>
                                    Yes. If you have staked in a pool and decide to withdraw earlier, the 5% commission will be required from the amount of the stake.</p>
                            </Panel>
                            <Panel header="What are the risks involved in staking?" key="12">
                                <p>
                                    Like all crypto transactions, staking carries inherent market risks that might result in the potential loss of staked tokens due to market fluctuations. It's crucial to perform comprehensive research before opting for staking and to thoroughly understand the risks associated with the selected staking plan.                        </p>
                            </Panel>
                            <Panel header="Is staking secured? How is security ensured?" key="13">
                                <p>
                                    Staking protocols prioritize the security of users's assets through multiple protective measures, including encryption, smart contracts, and audited protocols. Utilizing blockchain technology, security is reinforced by its inherent characteristics such as decentralized validation, cryptography, and immutable records, preventing unauthorized data changes. Additionally, our platform underwent a thorough audit conducted by Certik, a respected and one of the best security auditing firm. Their comprehensive analysis awarded us with an excellent score, indicating a robust security level. Additionally, extensive internal testing with high reliability has demonstrated the smart contract's security over an extended period of time.                         </p>
                            </Panel>
                            <Panel header="What happens if I forget to claim my staking rewards?" key="14">
                                <p>
                                    Unclaimed staking rewards will accumulate over time. You can claim rewards daily or at a convenient time.                        </p>
                            </Panel>
                            <Panel header="How are rewards calculated in staking?" key="15">
                                <p>
                                    "The Annual Percentage Rate (APR) is a standardized measure of the cost of borrowing money or the return on investment expressed as a yearly rate. For staking, loans, or other financial products, the APR accounts for interest rates as well as fees and other costs.<br />

                                    Here’s a basic formula to calculate the APR:<br />

                                    <span className="calcu-apr">
                                        APR = (Total Interest and Fees / Principal or Loan Amount) * (365 / Loan Term in Days) * 100</span>
                                    <br />

                                    Where:<br />
                                    <ul>
                                        <li>
                                            - Total Interest and Fees: The total interest paid over the term of the loan or the staking rewards.
                                        </li>
                                        <li>
                                            - Principal or Loan Amount: The original amount of money invested, staked, or borrowed.
                                        </li>
                                        <li>
                                            - Loan Term in Days: The total time period for which the money is borrowed or invested.
                                        </li>
                                    </ul>


                                </p>
                            </Panel>
                            <Panel header="Is there a minimum amount required to stake?" key="16">
                                <p>
                                    There is no minimum stake amount, you can stake as much as you want.                        </p>
                            </Panel>
                        </Collapse>
                    </div>
                </div>



            </div>
        </>
    )
}
export default Faqs