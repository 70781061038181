
import { Carousel } from "antd";
import { isMobile } from "react-device-detect";

const Slide = () => {

    return (
        <>
            <div className="container-slide">
                <div className="content-slide">
                    {isMobile ? (
                        <>
                            <Carousel effect="fade" arrows infinite={true}>
                                <div className="box-img">
                                    <img className="item-lan-slide" src="/images/landing/lan-slide-mo.png" alt="" />
                                </div>
                                <div className="box-img">
                                    <img className="item-lan-slide" src="/images/landing/lan-slide1-mo.png" alt="" />                                </div>
                                <div className="box-img">
                                    <img className="item-lan-slide" src="/images/landing/lan-slide2-mo.png" alt="" />
                                </div>
                                <div className="box-img">
                                    <img className="item-lan-slide" src="/images/landing/lan-slide3-mo.png" alt="" />
                                </div>

                            </Carousel>
                        </>
                    ) : (
                        <>
                            <Carousel effect="fade" arrows infinite={true}>
                                <div className="box-img">
                                    <img className="item-lan-slide" src="/images/landing/lan-slide.png" alt="" />
                                </div>
                                <div className="box-img">
                                    <img className="item-lan-slide" src="/images/landing/lan-slide1.png" alt="" />
                                </div>
                                <div className="box-img">
                                    <img className="item-lan-slide" src="/images/landing/lan-slide2.png" alt="" />
                                </div>
                                <div className="box-img">
                                    <img className="item-lan-slide" src="/images/landing/lan-slide3.png" alt="" />
                                </div>

                            </Carousel>
                        </>
                    )}

                </div>


            </div>
        </>
    )
}

export default Slide