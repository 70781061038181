import { useQuery } from "@tanstack/react-query";
import { affiliateApi } from "../api/affiliate";
import { useAccount } from "wagmi";

export const GET_DASHBOARD_AFFILIATE = "GET_DASHBOARD_AFFILIATE";

export const useGetDashboardAffiliate = () => {
  const { address } = useAccount();
  return useQuery({
    queryKey: [GET_DASHBOARD_AFFILIATE, address],
    queryFn: async () => await affiliateApi.getDashboard(address),
    enabled: !!address,
  });
};
