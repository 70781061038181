import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FB_URL, TELE_URL, X_URL, YT_URL } from "../../constants";

const Investment = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const goToTele = () => {
    window.open(`${TELE_URL}`);
  };
  const goToFB = () => {
    window.open(`${FB_URL}`);
  };
  const goToX = () => {
    window.open(`${X_URL}`);
  };
  const goToYT = () => {
    window.open(`${YT_URL}`);
  };

  return (
    <div className="container-landing-top h-[800px]">
      <div className="investment-landing !container md:px-20 px-4">
        {/* <div className="logo-invest">
                    <img src="./images/landing/logo-invest.png" alt="" />
                </div> */}
        <div className="wap-text-invest">
          <div className="wrap-text-inves">
            <span
              className="title1-inves"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Revolutionizing Real Estate{" "}
            </span>
            <span
              className="title2-inves"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              Investment
            </span>
          </div>
          <div
            className="dec-invest"
            data-aos="fade-up"
            data-aos-duration="1400"
          >
            Invest in real estate with ease and flexibility. MeyFi harnesses
            blockchain technology to make property investment accessible,
            liquid, and secure for everyone.
          </div>
          <div
            className="list-coin"
            data-aos="fade-up"
            data-aos-duration="1600"
          >
            <div className="sol-item">
              <img onClick={goToYT} src="./images/landing/yt.png" alt="" />
            </div>
            <div className="sol-item">
              <img onClick={goToX} src="./images/landing/f-x1.png" alt="" />
            </div>
            <div className="sol-item">
              <img onClick={goToTele} src="./images/landing/tele.png" alt="" />
            </div>
            <div className="sol-item">
              <img onClick={goToFB} src="./images/landing/fb.png" alt="" />
            </div>
          </div>
        </div>

        {/* <div className="explore-more" data-aos="fade-up" data-aos-duration="1000">
                    <button className='btn-launch'>
                        <div className="wrap-btn">
                            <img className='icon-boost' src="./images/landing/icon-boot.png" alt="" />
                            <span>
                                Explore more
                            </span>
                        </div>
                    </button>
                </div> */}
        <div className="absolute md:top-12 top-0 md:right-2 right-0 mx-auto md:w-auto w-screen flex items-center justify-center">
          <img
            className="md:w-[400px] w-[273px] aspect-square"
            src="./images/landing/img-invest.gif"
            alt=""
          />
        </div>

        <div className="img-meyfi ">
          <img src="./images/landing/mey-fi.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Investment;
