import { useQuery } from "@tanstack/react-query";
import { affiliateApi } from "../api/affiliate";
import { useAccount } from "wagmi";

export const GET_REFERRAL_COMMISSION = "GET_REFERRAL_COMMISSION";

export const useGetReferralCommission = ({
  pageIndex,
}: {
  pageIndex?: number;
}) => {
  const { address } = useAccount();

  return useQuery({
    queryFn: async () =>
      await affiliateApi.getReferralCommission({
        ownerAddress: address,
        pageIndex,
      }),
    queryKey: [GET_REFERRAL_COMMISSION, address, pageIndex],
    enabled: !!address,
  });
};
