import { useNavigate } from "react-router-dom";

const Launch = () => {
  const naigation = useNavigate();

  const handleLaunchApp = () => {
    naigation("/apps");
  };
  return (
    <>
      <div className="container-launch container">
        <div
          className="content-launch"
          data-aos="zoom-in"
          data-aos-duration="1400"
        >
          <div className="launch-left">
            <div className="title-launch">Launch on MeyFi App</div>
            <div className="dec-launch">
              Revolutionizing Real Estate Investment
            </div>
            <div className="launch-new">
              <button className="btn-launch-new" onClick={handleLaunchApp}>
                {" "}
                Launch App
              </button>
            </div>
          </div>
          <div className="launch-right">
            <img src="./images/landing/img-invest.gif" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Launch;
