/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return, no-param-reassign */
import axios from "axios";
import { API_URL } from "../constants/index";
import {
    createStore,
    createHook,
    createContainer,
    createSubscriber,
} from "react-sweet-state";

const StoreAPI = createStore({
    initialState: {

    },
    actions: {
        info:
            (params) =>
                ({ getState, setState }) => {
                    return new Promise((resolve, reject) => {
                        axios.get(`${API_URL}market/info`).then((res) => {
                            resolve(res?.data?.data)
                        });
                    });
                },
    },
    name: "StoreAPI",
});

export const useHookAPI = createHook(StoreAPI);
export const Container = createContainer(StoreAPI, {
    onInit:
        () =>
            ({ setState }, props) => {
                setState({ ...props });
            },
});
export const Subscriber = createSubscriber(StoreAPI);
