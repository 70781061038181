import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import MeynetworkLarge from "../../../assets/icons/svg/meynetwork-large.svg";
import { GradientButton } from "../../../components/ui/GradientButton";
import Faqs from "../staking/faqs";
import { useHookAPI } from "../../../utils/storeAPI";
import { isMobile } from "react-device-detect";

const stakeStepsData = [
    {
        title: "Connect Your Wallet",
        description: "Connect your wallet to get started",
    },
    {
        title: "Choose Stake Pool",
        description: "Stake, lend, or buy fractional real estate tokens.",
    },
    {
        title: "Input Stake Amount",
        description: "Use our marketplace to buy, sell, or trade your assets.",
    },
    {
        title: "Earn Rewards For Staking",
        description:
            "Watch your investments grow with secure and transparent returns.",
    },
];

const utilitiesData = [
    "Staking to earn extensive benefits",
    "Earn Lending Funds and Boosted APY",
    "DAO Voting Governance",
    "Platform Fee",
    "Membership",
    "Incentives",
];

const HomePage = () => {
    const naigation = useNavigate();
    const [state, actions] = useHookAPI();
    const [objInfo, setObjInfo]: any = useState()

    function gotoStake() {
        naigation("/apps/staking");
        sessionStorage.setItem("keyActiveMenu", '2')
        window.location.reload()
    }

    const getListInfo = () => {
        actions.info().then((res: any) => {
            setObjInfo(res)
        })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    useEffect(() => {
        getListInfo()
    }, [])


    const infoData = [
        {
            title: "Total Supply",
            value: "2,300,000,000",
        },
        {
            title: "Total Holders",
            value: objInfo?.totalHolder,
        },
        {
            title: "Mey Price",
            value: `$${objInfo?.price}`,
        },
        {
            title: "Market Cap",
            value: `$${objInfo?.marketCap}`,
        },
        {
            title: "Circulating Supply",
            value: objInfo?.cirSupply,
        },
        {
            title: "Total Txs",
            value: objInfo?.totalTnx,
        },
    ];
    return (
        <div className={isMobile ? "py-4 px-4 font-radioCanada" : "py-4 px-6 font-radioCanada"}>
            {/*Banner*/}
            <div
                style={{
                    backgroundImage: "url('/images/banner-home.png')",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                }}
                className={isMobile ? "border flex-col border-primary p-6 pr-11 flex justify-between items-center rounded-[24px] relative z-1" : "border border-primary p-6 pr-11 flex justify-between items-center rounded-[24px] relative z-1"}
            >
                <div className="flex flex-col gap-y-1.5 max-w-[532px] text-start">
                    <div className={isMobile ? "text-white font-semibold text-[24px]" : "text-white font-semibold text-[36px]"}>
                        WELCOME TO{" "}
                        <span className={isMobile ? "text-secondary font-semibold text-[24px]" : "text-secondary font-semibold text-[36px]"}>
                            MEY NETWORK
                        </span>
                    </div>
                    <div className={isMobile ? "text-1xl text-white" : "text-xl text-white"}>
                        Experience Secure Staking, Unlock Maximum Rewards
                    </div>
                </div>
                <div className="logo-landing">
                    <img alt="Logo" src={MeynetworkLarge} />
                </div>
            </div>
            {/*  Info */}
            <div className="grid grid-cols-10 gap-x-4 mt-4">
                <div className={isMobile ? "col-span-12 grid grid-cols-2 gap-4 items-center" : "col-span-6 grid grid-cols-3 gap-4 items-center"}>
                    {infoData.map(({ title, value }, index: number) => (
                        <InfoItems key={index} title={title} value={value} />
                    ))}
                </div>
                <div className={isMobile ?
                    "rounded-[12px] mt-4 p-4 flex flex-col gap-y-2 bg-[rgba(59,63,69,0.30)] border border-[rgba(59,63,69,0.30)] transition hover:shadow-[0_0_16px_0_#54FFFF] hover:cursor-pointer col-span-12"
                    :
                    "rounded-[12px] p-4 flex flex-col gap-y-2 bg-[rgba(59,63,69,0.30)] border border-[rgba(59,63,69,0.30)] transition hover:shadow-[0_0_16px_0_#54FFFF] hover:cursor-pointer col-span-4"
                }>
                    <div className="text-[20px] font-medium text-white">
                        Audits & Compliance
                    </div>
                    <div className=" flex justify-center h-[130px] p-2.5">
                        <img alt="" src="/images/home/certik.png" />
                    </div>
                </div>
            </div>
            {/*How to Stake*/}
            <div className="mt-8">
                <div className="text-xl font-medium text-white">How to stake?</div>
                <div className={isMobile ? "mt-2.5 text-secondary text-[24px] font-medium" : "mt-2.5 text-secondary text-[32px] font-medium"}>
                    Step-by-Step Staking Guide
                </div>
                <div className="mt-9">
                    <GradientButton onClick={gotoStake} className="h-10 w-[200px]">
                        <div className="text-white">Stake Now</div>
                    </GradientButton>
                </div>
                <div className="mt-9">
                    <div className={isMobile ? "grid grid-cols-1 gap-x-5" : "grid grid-cols-4 gap-x-5"}>
                        {stakeStepsData.map(({ title, description }, index) => (
                            <StakeSteps
                                key={index}
                                title={title}
                                description={description}
                                index={index}
                            />
                        ))}
                    </div>
                </div>
            </div>
            {/*Mey Utilities*/}
            {/* <div className="mt-14 py-9 px-4 bg-[rgba(59,63,69,0.30)] rounded-[16px] border border-[rgba(59,63,69,0.20)] backdrop-blur-[25px]">
                <div className="text-[32px] font-medium text-secondary">
                    Mey Utilities
                </div>
                <div className="mt-6 grid grid-cols-3 gap-x-5 gap-y-6">
                    {utilitiesData.map((item, index) => (
                        <UtilitiesItems key={index} text={item} />
                    ))}
                </div>
            </div> */}
            {/*Buy Mey*/}
            <MeyUlti />
            <MeyEcosystem />
            <div className="mt-10">
                <div className="text-[32px] text-secondary font-medium">
                    Buy Mey now on
                </div>
                <div className={isMobile ? "grid grid-cols-2 gap-x-5 mt-6" : "grid grid-cols-4 gap-x-5 mt-6"}>
                    <BuyItems1 />
                    <BuyItems2 />
                    <BuyItems />
                    <BuyItems />
                </div>
            </div>
            {/*Audit & Partner*/}
            <div className="mt-16 py-9 px-4 bg-[rgba(59,63,69,0.30)] rounded-[16px] border border-[rgba(59,63,69,0.20)] backdrop-blur-[25px]">
                <div className="text-[32px] font-medium text-secondary mb-6">
                    Backers & Partners
                </div>
                <div className={isMobile ? "grid grid-cols-2 gap-x-4 gap-y-4" : "grid grid-cols-4 gap-x-5 gap-y-6"}>
                    {Array.from({ length: 16 })
                        .map((_, index) => (
                            <PartnerItems index={index} />
                        ))}
                </div>
            </div>
            <div className={isMobile ? "mt-2" : "mt-20"}>
                <Faqs />
            </div>
        </div>
    );
};

const InfoItems = ({ title, value }: { title: string; value: string }) => {
    return (
        <div className="rounded-[12px] p-4 flex flex-col gap-y-2 bg-[rgba(59,63,69,0.30)] border border-[rgba(59,63,69,0.20)] text-start transition hover:shadow-[0_0_16px_0_#54FFFF] hover:cursor-pointer">
            <div className={isMobile ? "text-[#707A8A] text-1xl uppercase" : "text-[#707A8A] uppercase"}>{title}</div>
            <div className={isMobile ? "text-white text-1xl font-medium" : "text-white text-2xl font-medium"}>{value}</div>
        </div>
    );
};

const StakeSteps = ({
    title,
    description,
    index,
}: {
    title: string;
    description: string;
    index: number;
}) => {
    return (
        <div className={isMobile ?
            "h-[auto] mb-4 p-3 bg-[rgba(59,63,69,0.30)] rounded-[16px] border border-[rgba(59,63,69,0.2)] backdrop-blur-[25px] transition hover:shadow-[0_0_16px_0_#54FFFF] hover:cursor-pointer"
            :
            "h-[264px] p-3 bg-[rgba(59,63,69,0.30)] rounded-[16px] border border-[rgba(59,63,69,0.2)] backdrop-blur-[25px] transition hover:shadow-[0_0_16px_0_#54FFFF] hover:cursor-pointer"
        }>
            <div className="flex justify-between items-center ">
                <img alt="" src={`/images/home/stake-step-${index + 1}.png`} />
                <div className="text-primary text-[14px]">Step {index + 1}</div>
            </div>
            <div className={isMobile ? "mt-2 text-xl font-medium text-white text-start" : "mt-10 text-xl font-medium text-white text-start"}>
                {title}
            </div>
            <div className={isMobile ? "mt-2 text-[#CDD5DF] text-[14px] text-start" : "mt-4 text-[#CDD5DF] text-[14px] text-start"}>
                {description}
            </div>
        </div>
    );
};

const UtilitiesItems = ({ text }: { text: string }) => {
    return (
        <div className="p-4 bg-[rgba(59,63,69,0.30)] rounded-[16px] border border-[rgba(59,63,69,0.20)] backdrop-blur-[25px] flex gap-x-4 items-center group transition hover:cursor-pointer hover:border-secondary">
            <img alt="" src="/images/home/star.png" />
            <div className="text-white text-xl font-medium text-start group-hover:text-secondary ">
                {text}
            </div>
        </div>
    );
};

const BuyItems1 = () => {
    return (
        <div className="p-4 bg-[rgba(59,63,69,0.30)] rounded-[16px] border border-[rgba(59,63,69,0.20)] backdrop-blur-[25px] flex gap-x-4 items-center buy-item">
            <img className="home-buy" alt="" src="/images/home/buy-1.png" />
            {/* <img alt="" src="/images/home/coming-soon.png" /> */}
            {/* <div className="text-white text-xl font-medium text-start uppercase">
                Coming Soon
            </div> */}
        </div>
    );
};
const BuyItems2 = () => {
    return (
        <div className="p-2 bg-[rgba(59,63,69,0.30)] rounded-[16px] border border-[rgba(59,63,69,0.20)] backdrop-blur-[25px] flex gap-x-4 items-center buy-item">
            <img className="home-buy" alt="" src="/images/home/buy-2.png" />
            {/* <img alt="" src="/images/home/coming-soon.png" /> */}
            {/* <div className="text-white text-xl font-medium text-start uppercase">
                Coming Soon
            </div> */}
        </div>
    );
};
const BuyItems = () => {
    return (
        <div className={isMobile ?
            "mt-4 p-2 bg-[rgba(59,63,69,0.30)] rounded-[16px] border border-[rgba(59,63,69,0.20)] backdrop-blur-[25px] flex gap-x-4 items-center"
            :
            "p-2 bg-[rgba(59,63,69,0.30)] rounded-[16px] border border-[rgba(59,63,69,0.20)] backdrop-blur-[25px] flex gap-x-4 items-center"
        }>
            <img alt="" src="/images/home/coming-soon.png" />
            <div className={isMobile ? "text-white text-[12px] font-medium text-start uppercase" : "text-white text-xl font-medium text-start uppercase"}>
                Coming Soon
            </div>
        </div>
    );
};

const PartnerItems = ({ index }: { index: number }) => {
    return (
        <>
            {index === 4 ? ('') : (
                <>
                    <div className={isMobile ?
                        "padd-0-15 bg-[rgba(59,63,69,0.30)] rounded-[16px] border border-[rgba(59,63,69,0.20)] backdrop-blur-[25px] h-[87px] flex items-center justify-center transition hover:shadow-[0_0_16px_0_#54FFFF] hover:cursor-pointer"
                        :
                        "bg-[rgba(59,63,69,0.30)] rounded-[16px] border border-[rgba(59,63,69,0.20)] backdrop-blur-[25px] h-[87px] flex items-center justify-center transition hover:shadow-[0_0_16px_0_#54FFFF] hover:cursor-pointer"
                    }>
                        <img alt="" src={`/images/home/partners/partner-${index + 1}.png`} />
                    </div>
                </>
            )}
        </>

    );
};

const MeyUlti = () => {
    return (
        <>
            <div className="round-ecosystem">
                <div className="box-img">
                    <img src="/images/eco-11.png" alt="" />
                </div>
            </div>
        </>
    )
}
const MeyEcosystem = () => {
    return (
        <>
            <div className="round-ecosystem">
                <div className="box-img">
                    <img src="/images/eco-22.png" alt="" />
                </div>
            </div>
        </>
    )
}

export default HomePage;
