import React, { useEffect, useState } from "react";
import type { MenuProps } from "antd";
import { Button, Menu } from "antd";
import "./style.scss";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { isMobile } from "react-device-detect";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group",
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

const items = [
    getItem(
        <Link to="/apps">Home</Link>,
        "1",
        <>
            <Link to="/apps">
                <img src="/images/menu/home.png" alt="" />
                <img src="/images/menu/home-1.png" alt="" />
            </Link>
        </>,
    ),
    getItem(
        <Link to="/apps/staking">Staking</Link>,
        "2",
        <>
            <Link to="/apps/staking">
                <img src="/images/menu/stake.png" alt="" />
                <img src="/images/menu/stake-2.png" alt="" />
            </Link>
        </>,
    ),
    getItem(
        <Link to="/apps/p2p-lending">P2P Lending</Link>,
        "4",
        <>
            <Link to="/apps/p2p-lending">
                <img src="/images/menu/lending.png" alt="" />
                <img src="/images/menu/lending-1.png" alt="" />
            </Link>
        </>,
    ),
    getItem(
        <Link to="/apps/pto">PTO</Link>,
        "5",
        <>
            <Link to="/apps/pto">
                <img src="/images/menu/pto-1.png" alt="" />
                <img src="/images/menu/pto.png" alt="" />
            </Link>
        </>,
    ),
    getItem(
        <Link to="/apps/marketplace">Marketplace</Link>,
        "6",
        <>
            <Link to="/apps/marketplace">
                <img src="/images/menu/store.png" alt="" />
                <img src="/images/menu/store-1.png" alt="" />
            </Link>
        </>,
    ),

    getItem(
        <Link to="/apps/history">History</Link>,
        "8",
        <>
            <Link to="/apps/history">
                <img src="/images/menu/his.png" alt="" />
                <img src="/images/menu/his-1.png" alt="" />
            </Link>
        </>,
    ),
    getItem(
        <Link to="/apps/affiliate">Affiliate</Link>,
        "affiliate",
        <>
            <Link to="/apps/affiliate">
                <img src="/images/menu/affiliate.png" alt="" />
                <img src="/images/menu/affiliate-1.png" alt="" />
            </Link>
        </>,
    ),
    getItem(
        <Link to="/apps/portfolio">My Account</Link>,
        "7",
        <>
            <Link to="/apps/portfolio">
                <img src="/images/menu/por.png" alt="" />
                <img src="/images/menu/por-1.png" alt="" />
            </Link>
        </>,
    ),
    // getItem(
    //     "More",
    //     "sub2",
    //     <>
    //         <img src="/images/more.png" alt="" />
    //     </>,
    //     [
    //         // getItem('Option 6', '7'),
    //         // getItem('Option 10', '8'),
    //     ],
    // ),
];
const SideBar = (props: any) => {
    const [collapsed, setCollapsed] = useState(false);
    const [current, setCurrent] = useState<any>("1");

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const onClick: MenuProps["onClick"] = (e) => {
        setCurrent(e.key);
        sessionStorage.setItem("keyActiveMenu", e.key);
        if(isMobile) {
            window.location.reload()
        }
    };

    let keyActiveDrop: any;
    if (
        typeof window !== "undefined" &&
        window.sessionStorage.getItem("keyActiveMenu") !== undefined
    ) {
        keyActiveDrop = window.sessionStorage.getItem("keyActiveMenu");
    }
    function gotoDocs() {
        window.open("https://docs.mey.network/mey-network");
    }

    const sendData = () => {
        props?.callbackFunction(collapsed);
    }


    useEffect(() => {
        sendData()
    }, [collapsed]);

    return (
        <>
            <div className="menu-wrap">
                <div className={clsx("menu-sidebar", collapsed && "collapse-menu")}>
                    {isMobile ?
                        <>
                            {""}
                        </>
                        :
                        <>
                            <div className="sidebar-top">
                                <Link className={clsx(collapsed && "flex justify-center")} to="/">
                                    <img
                                        className="h-7"
                                        src={
                                            collapsed ? "/images/logo-collapse.png" : "/images/logo.png"
                                        }
                                        alt=""
                                    />
                                </Link>
                                <div
                                    className={clsx(
                                        "sidebar-icon transition",
                                        !collapsed && "rotate-180",
                                    )}
                                    onClick={toggleCollapsed}
                                >
                                    <img src="/images/icon-sidebar.png" alt="" />
                                </div>
                            </div>
                        </>
                    }
                    <div className="sidebar-center">
                        <Menu
                            defaultSelectedKeys={keyActiveDrop}
                            onClick={onClick}
                            mode="inline"
                            theme="dark"
                            items={items}
                        />
                    </div>
                    <div className="sidebar-bottom">
                        <div className="row-contact">
                            {/* <Link to="/apps/p2p-lending"> */}
                            {/* <a href="http://portal-meyfi-group-dev.testproduct.site/" target="_blank">
                                <div className="item">
                                    <img src="/images/user/link.png" alt="" /> <span>Portal</span>
                                </div>
                            </a> */}

                            {/* </Link> */}
                            {isMobile ?
                                <>
                                    {""}
                                </>
                                :
                                <>
                                    <div className="item">
                                        <img src="/images/headphone.png" alt="" />{" "}
                                        <span>Help Center</span>
                                    </div>
                                </>
                            }
                            <div className="item" onClick={gotoDocs}>
                                <img src="/images/question.png" alt="" /> <span>Docs</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SideBar;
