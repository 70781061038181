import BigNumber from 'bignumber.js'

function isAllZeros(str :any) {
    return /^0+$/.test(str);
}

export const formatNumber = (number: any, max: any) => {
    if (Number(number) >= 1) {
        let [integerPart, decimalPart] = number.toString().split('.');
        if (integerPart !== '0') {
            integerPart = parseInt(integerPart).toLocaleString('en-US');
        }
        decimalPart = decimalPart ? decimalPart.substring(0, max) : '';
        if(isAllZeros(decimalPart)){
            decimalPart=''
        }
        return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
    } else  if (Number(number) < 1){
        const factor = Math.pow(10, max);
    return Math.floor(number * factor) / factor;
    //      let bigNum = new BigNumber(number);
    // return bigNum.toFixed(max, BigNumber.ROUND_DOWN);
    }else{
        return 0
} }
