import { message, Progress, Skeleton } from "antd";
import "./style.css";
import Faqs from "./faqs";
import { useHookStaked } from "./storeStaked";
import { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { Switch } from "antd";
import { NUM_FORMAT, RPC_BASE, WORK_CHAIN } from "../../../constants";
import abiStake from "./details/utils/abiStake.json";
import { formatNumber } from "./details/utils";
import { rendererCountDownList } from "./details/utils/render-countdown";
import { useAccount } from "wagmi";
import { useMutation } from "@tanstack/react-query";
import { AddRefPayload } from "../../../types/affiliate";
import { affiliateApi } from "../../../api/affiliate";
import { useHookAPI } from "../../../utils/storeAPI";
import { isMobile } from "react-device-detect";

const web3: any = new Web3(RPC_BASE);

const TopStakingList = ({ totalStaked, totalStaker, price }: any) => {

  return (
    <>
      <div className="content-top">
        {isMobile ? (
          <div className="item-bread-staking">
            Staking
          </div>
        ) : ('')}
        <div className="banner-top">
          {isMobile ? (
            <img src="/images/staking/banner-staking2-mo.png" alt="" />
          ) : (
            <img src="/images/staking/banner-staking2.png" alt="" />
          )}

        </div>
        <div className="info-top">
          <div className="total-stake-top">
            <div className="title-total-top">Total Staked Amount</div>
            <div className="num-total-top">
              {formatNumber(totalStaked, NUM_FORMAT)} <span>MEY</span>
            </div>
          </div>
          <div className="top-total-item">
            <div className="top-total-text">Total Value Staked:</div>
            <div className="top-total-amount">
              ${formatNumber(totalStaked * Number(price), NUM_FORMAT)}
            </div>
          </div>
          <div className="top-total-item">
            <div className="top-total-text">Total Platform Investors:</div>
            <div className="top-total-amount">{totalStaker}</div>
          </div>
        </div>
      </div>
    </>
  );
};

const Item = (props: any) => {
  const navigation = useNavigate();
  const { selectedNetworkId }: any = useWeb3ModalState();
  const [totalStakedItem, setTotalStakedItem]: any = useState(0);
  const [maxCapPool, setMaxCapPool]: any = useState(0);
  const [percent, setPercent]: any = useState(0);
  const [numberAPY, setNumberAPY]: any = useState(0);
  const [numberFee, setNumberFee]: any = useState(0);

  let index = props?.index;
  let item = props?.item;
  let stakingTimeSecond = item?.stakingTimeSecond;
  let price = props?.price

  let classBg = "item-stake-top";
  if (index % 3 === 0) {
    classBg = "item-stake-top";
  } else if (index % 3 === 1) {
    classBg = "item-stake-top1";
  } else if (index % 3 === 2) {
    classBg = "item-stake-top2";
  } else {
    classBg = "item-stake-top";
  }

  const contractStake = new web3.eth.Contract(
    abiStake as unknown as AbiItem,
    item?.poolContract,
  );



  function goToDetail(id: any) {
    if (id) {
      navigation(`/apps/staking/details?id=${id}`);
    }
  }

  const getTotalStaked = async () => {
    await contractStake.methods
      .tokenTotalStaked()
      .call()
      .then((res: any) => {
        let balance: any = web3.utils.fromWei(res, "ether");
        setTotalStakedItem(Number(balance));
      })
      .catch((err: any) => {
        setTotalStakedItem(0);
      });
  };

  const getMaxCap = async () => {
    await contractStake.methods
      .maxCap()
      .call()
      .then((res: any) => {
        let cap: any = web3.utils.fromWei(res, "ether");
        // let temp = Math.floor((Number(res) / 1e18) * 100000) / 100000;
        setMaxCapPool(Number(cap))
      })
      .catch((err: any) => {
        console.log("err", err);
        setMaxCapPool(0);
      });
  };

  const getNumberAPY = async () => {
    try {
      await contractStake.methods
        .stakingAPY()
        .call()
        .then((res: any) => {

          setNumberAPY(Number(res) / 100);
        })
        .catch((err: any) => {

          setNumberAPY(0);
        });
    } catch (error) {
      setNumberAPY(0);
    }
  };

  useEffect(() => {
    if (item?.poolContract) {
      getNumberAPY();
    } else {
      setNumberAPY(0);
    }
  }, [item]);

  useEffect(() => {
    if (item?.poolContract) {
      getTotalStaked();
      getMaxCap();
    } else {
      setMaxCapPool(0);
      setTotalStakedItem(0);
    }
  }, [item]);

  const getNumberFee = async () => {
    try {
      await contractStake.methods
        .earlyWithdrawFee()
        .call()
        .then((res: any) => {
          setNumberFee(Number(res) / 100);
        })
        .catch((err: any) => {
          setNumberFee(0);
        });
    } catch (error) {
      setNumberFee(0);
    }
  };

  useEffect(() => {
    if (item?.poolContract) {
      getNumberFee();
    } else {
      setNumberFee(0);
    }
  }, [item]);

  const calculaPercent = () => {
    if (maxCapPool > 0) {
      let temp = (totalStakedItem / maxCapPool) * 100;
      setPercent(Number(temp.toFixed(2)) || 0);
    } else {
      setPercent(0);
    }
  };

  useEffect(() => {
    calculaPercent();
  }, [item, totalStakedItem, maxCapPool]);

  return (
    <>
      <div className="wrap-item-stake">
        <div className="item-stake">
          <div className={classBg}>
            <div className="stake-top-left">
              <div className="logo-item-project">
                <img src="/images/staking/logo-pro1.png" alt="" />
              </div>
              <div className="name-item-project">
                <div className="name-item-top">{item?.poolName}</div>
                <div className="apy-item-top">
                  APR {formatNumber(numberAPY, NUM_FORMAT)}%
                </div>
              </div>
            </div>
            <div className="stake-top-right">
              <div className="content-item-topr">
                <div className="icon-day">
                  <img src="/images/staking/icon-day.png" alt="" />
                </div>
                <div className="lock-day-top">{item?.stackingTime}</div>
              </div>
            </div>
          </div>
          <div className="item-countdown">
            <div className="text-down">Stake ends in:</div>
            <div className="num-donw">
              {stakingTimeSecond && (
                <Countdown
                  date={stakingTimeSecond * 1000}
                  renderer={rendererCountDownList}
                />
              )}
            </div>
          </div>
          <div className="item-stake-body">
            <div className="line-body">
              <div className="line-body-left">Total value staked:</div>
              <div className="line-body-right">
                ${formatNumber(totalStakedItem * price, NUM_FORMAT)}
              </div>
            </div>
            <div className="line-body">
              <div className="line-body-left">APR:</div>
              <div className="line-body-right apy">
                {formatNumber(numberAPY, NUM_FORMAT)}%
              </div>
            </div>
            <div className="line-body">
              <div className="line-body-left">Early withdrawal fee:</div>
              <div className="line-body-right">
                {formatNumber(numberFee, NUM_FORMAT)}%
              </div>
            </div>
            <div className="pool-cap">
              <div className="title-pool-cap">
                <div className="title-cap-left">Pool cap:</div>
                <div className="title-cap-right">Remaining Pool:</div>
              </div>
              <div className="title-pool-cap">
                <div className="num-cap-left second">
                  {formatNumber(maxCapPool, NUM_FORMAT)} {item?.symbol}
                </div>
                <div className="num-cap-right second">
                  {formatNumber(maxCapPool - totalStakedItem, NUM_FORMAT)}{" "}
                  {item?.symbol}
                </div>
              </div>
              <div className="cap-progress">
                <Progress
                  percent={percent}
                  status="active"
                  strokeColor={{ from: "#00EFFF", to: "#0047FF" }}
                />
              </div>
            </div>
            <div className="button-stake">
              <button
                className="btn-stake"
                onClick={() => goToDetail(item?.poolId)}
              >
                Stake
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const StakingList = () => {
  const [state, actions] = useHookStaked();
  const [state1, actions1] = useHookAPI();
  const { selectedNetworkId }: any = useWeb3ModalState();
  const [listPool, setListPool]: any = useState([]);
  const [totalStaked, setTotalStaked]: any = useState(0);
  const [totalStaker, setTotalStaker]: any = useState(0);
  const [stakeOnly, setStakeOnly]: any = useState(false);
  const [objInfo, setObjInfo]: any = useState()
  const { address } = useAccount();
  const [tab, setTab]: any = useState(1);
  const location = useLocation();
  const account = useAccount();


  const getListInfo = () => {
    actions1.info().then((res: any) => {
      setObjInfo(res)
    })
      .catch((err: any) => {
        console.log('err', err)
      })
  }

  useEffect(() => {
    getListInfo()
  }, [])


  const { mutate } = useMutation({
    mutationFn: async (data: AddRefPayload) => await affiliateApi.addRef(data),
    onSuccess: () => {
      message.success("Add referral successful!");
    },
  });

  useEffect(() => {
    const params = new URLSearchParams(location?.search);
    const refAddress = params.get("ref");
    if (refAddress && account.address) {
      mutate({
        refAddress,
        ownerAddress: account.address,
      });
    }
  }, [location.search, account.address]);
  // 1 live
  // 2 finish

  const getListPool = async () => {
    try {
      let param = {
        ownerAddress: stakeOnly ? address || "" : "",
      };
      await actions
        .listPool(param)
        .then((res: any) => {
          setListPool(res || []);
        })
        .catch((err: any) => {
          setListPool([]);
        });
    } catch (error) {
      setListPool([]);
    }
  };

  useEffect(() => {
    getListPool();
  }, [stakeOnly]);

  let tempTotal = 0;
  let tempNumber = 0;
  const getTotalStaked = async () => {
    if (listPool?.length > 0) {
      for (let i = 0; i < listPool.length; i++) {
        let contractStake = new web3.eth.Contract(
          abiStake as unknown as AbiItem,
          listPool[i]?.poolContract,
        );
        await contractStake.methods
          .tokenTotalStaked()
          .call()
          .then((res: any) => {
            let balance: any = web3.utils.fromWei(res, "ether");
            tempTotal = tempTotal + Number(balance);
          })
          .catch((err: any) => {
            console.log("err", err);
            // setTotalStakedItem(0)
            // return 0
          });
        //---------
        await contractStake.methods
          .totalStakerCount()
          .call()
          .then((res: any) => {
            // console.log('res', res)

            tempNumber = tempNumber + Number(res);
          })
          .catch((err: any) => { });
      }
      setTotalStaked(tempTotal);
      setTotalStaker(tempNumber);
    }
  };

  useEffect(() => {
    if (listPool?.length > 0) {
      getTotalStaked();
    } else {
      setTotalStaked(0);
    }
  }, [listPool]);

  const onChange = (checked: boolean) => {
    setStakeOnly(checked);
  };

  const handleActiveTab = (value: any) => {
    setTab(value);
  };

  let listPoolLive = listPool.filter((element: any) => element.status === 1);
  let listPoolFinish = listPool.filter((element: any) => element.status === 2);


  return (
    <>
      <div className="container-staking-list">
        <TopStakingList
          price={objInfo?.price}
          totalStaked={totalStaked}
          totalStaker={totalStaker} />
        <div className="title-early">
          <img src="/images/staking/icon-early.png" alt="" />
          <span>Early contributor program</span>
        </div>
        <div className="site-status">
          <div className="btn-status">
            <div
              className={tab === 1 ? "btn-stt-live-acc" : "btn-stt-live"}
              onClick={() => handleActiveTab(1)}
            >
              Live
            </div>
            <div
              className={tab === 2 ? "btn-stt-finis-acc" : "btn-stt-finis"}
              onClick={() => handleActiveTab(2)}
            >
              Finished
            </div>
          </div>
          {address ? (
            <div className="btn-only">
              <Switch value={stakeOnly} onChange={onChange} size="small" />
              <span className='stake-only'>Staking only</span>
            </div>
          ) : ('')}

        </div>

        {listPool === 0 ? (
          <>
            <div className="ske-list-stake">
              <Skeleton paragraph={{ rows: 10 }} />
              <Skeleton paragraph={{ rows: 10 }} />
              <Skeleton paragraph={{ rows: 10 }} />
            </div>
          </>
        ) : (
          <>
            {listPool.length === 0 ? (
              <div className="box-no-data-stake">
                <img className="img-empty" src="/images/empty1.png" alt="" />
              </div>
            ) : (
              <>
                {tab === 1 && (
                  <>
                    {listPoolLive.length === 0 ? (
                      <div className="box-no-data-stake">
                        <img
                          className="img-empty"
                          src="/images/empty1.png"
                          alt=""
                        />
                      </div>
                    ) : (
                      <div className="list-item-stake">
                        {listPoolLive.map((item: any, i: any) => (
                          <Item index={i} item={item} price={objInfo?.price} />
                        ))}
                      </div>
                    )}
                  </>
                )}
                {tab === 2 && (
                  <>
                    {listPoolFinish.length === 0 ? (
                      <div className="box-no-data-stake">
                        <img
                          className="img-empty"
                          src="/images/empty1.png"
                          alt=""
                        />
                      </div>
                    ) : (
                      <div className="list-item-stake">
                        {listPoolFinish.map((item: any, i: any) => (
                          <Item index={i} item={item} price={objInfo?.price} />
                        ))}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}

        <div className="faq-staking">
          <Faqs />
        </div>
      </div>
    </>
  );
};
export default StakingList;
