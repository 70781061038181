import React, { useEffect } from "react";
import { TypeAnimation } from "react-type-animation";
import { isMobile } from "react-device-detect";
import AOS from "aos";
import "aos/dist/aos.css";

const Why = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="wrap-why" id='why'>
      <div className="container">
        <div className="top-why">
          <div className="icon-why" data-aos="fade-up" data-aos-duration="1000">
            <img src="./images/landing/icon-why.png" alt="" />
          </div>
          <div
            className="title-why"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Why MeyFi
          </div>
          <div className="dec-why" data-aos="fade-up" data-aos-duration="1000">
            Invest in real estate with ease and flexibility. MeyFi harnesses
            blockchain technology to make property investment accessible,
            liquid, and secure for everyone.
          </div>
          {/* <div className="btn-why">
                        <button className='btn-launch'>
                            <div className="wrap-btn">
                                <img className='icon-boost' src="./images/landing/icon-boot.png" alt="" />
                                <span>
                                   
                                </span>
                            </div>
                        </button>
                    </div> */}
          <div className="asb-img">
            <img className="asb1" src="./images/landing/img-why1.png" alt="" />
            <img className="asb2" src="./images/landing/img-why2.png" alt="" />
          </div>
        </div>
        <div className="list-why">
          <div className="item-why" data-aos="zoom-in" data-aos-duration="1200">
            <div className="w-img-item">
              <img src="./images/landing/img-item-w.png" alt="" />
            </div>
            <div className="w-name-item">Accessible</div>
            <div className="w-dec-item">
              Own a piece of premium properties with minimal investment.
              Fractional ownership opens the doors to everyone.
            </div>
          </div>
          <div className="item-why" data-aos="zoom-in" data-aos-duration="1400">
            <div className="w-img-item">
              <img src="./images/landing/img-item-w1.png" alt="" />
            </div>
            <div className="w-name-item">Liquid</div>
            <div className="w-dec-item">
              Trade your property tokens anytime on our marketplace. Enjoy the
              flexibility to buy, sell, and manage your assets freely.
            </div>
          </div>
          <div className="item-why" data-aos="zoom-in" data-aos-duration="1600">
            <div className="w-img-item">
              <img src="./images/landing/img-item-w2.png" alt="" />
            </div>
            <div className="w-name-item">Secure</div>
            <div className="w-dec-item">
              Powered by blockchain, MeyFi ensures all transactions are
              transparent, secure, and immutable.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Why;
