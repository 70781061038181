import Investment from "./investment";
import Offer from "./offer";
import Why from "./why";
import "./style.scss";
import { useEffect } from "react";
import Flow from "./flow";
import Slide from "./slide";
import Launch from "./launch";

const LandingPage = () => {
  useEffect(() => {
    window.localStorage.removeItem("keyActiveMenu");
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="wrap-launch">
        <Investment />
        <Why />
        <Offer />
        <Flow />
        <Slide />
        <Launch />
      </div>
    </>
  );
};

export default LandingPage;
