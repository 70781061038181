/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return, no-param-reassign */
import axios from "axios";
import { API_URL } from "../../../constants/index";
import {
    createStore,
    createHook,
    createContainer,
    createSubscriber,
} from "react-sweet-state";

const StoreAccount = createStore({
    initialState: {},
    actions: {
        getHistoryStake:
            (params) =>
                ({ getState, setState }) => {
                    return new Promise((resolve, reject) => {
                        axios.post(`${API_URL}account/your-stake`, { ...params })
                            .then((res) => {
                                resolve(res?.data?.data)
                            })

                    });
                },
        getHistory:
            (params) =>
                ({ getState, setState }) => {
                    return new Promise((resolve, reject) => {
                        axios.post(`${API_URL}account/get-history`, { ...params })
                            .then((res) => {
                                resolve(res?.data)

                            })

                    });
                },
    },
    name: "StoreAccount",
});

export const useHookAccount = createHook(StoreAccount);
export const Container = createContainer(StoreAccount, {
    onInit:
        () =>
            ({ setState }, props) => {
                setState({ ...props });
            },
});
export const Subscriber = createSubscriber(StoreAccount);
