import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import "./style.css";
import { Modal } from "antd";
import { useState } from "react";

const Header = () => {
  const naigation = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleLaunchApp = () => {
    naigation("/apps");

  };
  function gotoMenu() {
    naigation(`/`);
  }

  return (
    <>
      <div className="header-landing">
        <div className="content-header-land">
          <div className="header-land-left">
            <div className="logo-header-land" onClick={gotoMenu}>
              <div className="img-logo-land">
                <img src="./images/landing/logo-land.svg" alt="" />
              </div>
              <div className="name-logo-land">Mey Fi</div>
            </div>
            <div className="item-header-land">
              <a onClick={gotoMenu} href="#why">
                Why MeyFi
              </a>
            </div>
            <div className="item-header-land">
              <a onClick={gotoMenu} href="#feature">
                Features
              </a>
            </div>
            <div className="item-header-land">
              <a onClick={gotoMenu} href="#how">
                How it work
              </a>
            </div>
            {/* <div className="item-header-land">
                            <a href="#about">
                                About
                            </a>

                        </div> */}
            {/* <div className="item-header-land">
                            Docs
                        </div> */}
          </div>
          <div className="header-land-right">
            <button className="btn-launch-app" onClick={handleLaunchApp}>
              Launch App
            </button>
          </div>
        </div>
      </div>
      <Modal
        title="Basic Modal"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        wrapClassName="modal-mobie"
        footer={false}
      >
        <div className="modal-mobie-content">
          <img className="img-modal-mobie" src="/images/landing/modal-mobie.png" alt="" />
          <div className="text-modal-mobie">
            This product is now available on desktop
          </div>
          <div className="dec-modal-mobie">
            Comming soon for mobile
          </div>
          <button className="btn-launch-app" onClick={handleCancel}>Confirm</button>
        </div>
      </Modal>
    </>
  );
};

export default Header;
