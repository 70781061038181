import React from "react";

const ComingSoon = () => {
    return (
        <>
            <div className="box-img">
                <img src="/images/coming-soon.png" alt="" />
            </div>
        </>
    )
}
export default ComingSoon