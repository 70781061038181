import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import KycPage from "../kyc";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import Synaps from "@synaps-io/react-verify";
import country from "./country";
import { Modal, Select, Skeleton, Spin } from 'antd';
import { AppHome } from "../../../../api/appHome";
import { toast } from "react-toastify";
import axios from "axios";
import { KYC_STATUS } from "./index.d"
import EmptyConnect from "../../../../components/empty-connect";
import { LoadingOutlined } from '@ant-design/icons';

const EditProfile = (props: any) => {
    const { address } = useAccount();
    const naigation = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dataEdit, setDataEdit]: any = useState()
    const [dataKyc, setDataKyc]: any = useState()
    const [dataKycInit, setDataKycInit]: any = useState()
    const [dataInfo, setDataInfo]: any = useState()
    const [valueFirstName, setValueFirstName] = useState(dataInfo?.dataAccount?.firstName)
    const [valueLastName, setValueLastName] = useState(dataInfo?.dataAccount?.lastName)
    const [valuePhone, setValuePhone] = useState(dataInfo?.dataAccount?.phone)
    const [valueEmail, setValueEmail] = useState(dataInfo?.dataAccount?.email)
    const [isFinish, setIsFinish] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setValueFirstName(dataInfo?.dataAccount?.firstName)
        setValueLastName(dataInfo?.dataAccount?.lastName)
        setValuePhone(dataInfo?.dataAccount?.phone)
        setValueEmail(dataInfo?.dataAccount?.email)
    }, [dataInfo]);

    const onChangeFirstName = (e: any) => {
        setValueFirstName(e.target.value)
    }

    const onChangeLastName = (e: any) => {
        setValueLastName(e.target.value)
    }

    const onChangePhone = (e: any) => {
        const phone = e.target.value;
        if (/^\d*$/.test(phone)) {
            setValuePhone(phone);
        }
    };

    const onChangeEmail = (e: any) => {
        const email = e.target.value;
        setValueEmail(email);
    };

    const AccountInfo = async () => {
        try {
            let res = await AppHome.AccountInfo(
                address
            );
            if (res.data.success === true) {
                setDataInfo(res.data.data);
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    const SaveAccountEdit = async () => {
        setLoading(true);
    
        let params = {
            walletAddress: address,
            firstName: valueFirstName,
            lastName: valueLastName,
            phone: valuePhone,
            email: valueEmail,
        };
    
        if (valueEmail && !valueEmail.endsWith("@email.com") && !valueEmail.endsWith("@gmail.com")) {
            toast.error("Email or gmail must end with @email.com or @gmail.com", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoading(false); 
        } else {
            try {
                let res = await AppHome.EditAccountInfo(params);
                if (res.data.success) {
                    AccountInfo();
                    setDataEdit(res.data.data);
                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    window.location.href = '/apps/portfolio';
                } else {
                    toast.error(res.data.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            } catch (error) {
                console.log("Error: ", error);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (address) {
            AccountInfo()
        }
    }, [address]);

    const [selectedFile, setSelectedFile]: any = useState(null);

    const [preview, setPreview] = useState(null);
    const fileInputRef: any = useRef(null);

    const fileSelectedHandler = (event: any) => {
        const file = event.target.files[0];
        setSelectedFile(file);

        // Create a preview
        const reader: any = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const handleImageClick = async () => {
        fileInputRef.current.click();
    };

    const handleUploadFiel = () => {
        toast.error("Please choose Images avatar", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    const handleUpload = async () => {
        if (selectedFile.size > 5 * 1024 * 1024) {
            toast.error("Photos cannot exceed 5 MB", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }

        const formData: any = new FormData();
        formData.append('image', selectedFile);
        formData.append('walletAddress', address);
        const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}account/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
        if (res.data.success === true) {
            toast.success(res.data.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            naigation(`/apps/portfolio`)
        } else {
            toast.error(res.data.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const GetDataKycInit = async () => {
        let params = {
            ownerAddress: address
        }
        try {
            let res = await AppHome.getDataKycInit(
                params
            );
            if (res.data.success === true) {
                setDataKycInit(res.data.data);
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };


    const GetDataKyc = async () => {
        let params = {
            ownerAddress: address
        }
        try {
            let res = await AppHome.getDataKyc(
                params
            );
            if (res.data.success === true) {
                setDataKyc(res.data.data);
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    useEffect(() => {
        if (address) {
            if (dataKyc === null || dataKyc === undefined) {
                GetDataKycInit()
            }
        }
    }, [dataKyc, address]);

    useEffect(() => {
        if (address) {
            GetDataKyc()
        }
    }, [address]);

    const handleFinish = () => {
        setIsFinish(true);
        setIsModalOpen(false);
    };

    const renderStatus = (numStt: any) => {
        switch (numStt) {
            case KYC_STATUS.SUBMISSION_REQUIRED:
                return "Unverified"
            case KYC_STATUS.PENDING:
                return "Pending"
            case KYC_STATUS.VERIFIED:
                return "Verified"
            case KYC_STATUS.CANCELLED:
                return "Cancel"
            default:
                return "TBA"
        }
    }


    return (
        <>
            <div className="section-grow mar-b-100-p2p">
                {address === null || !address ?
                    <>
                        <EmptyConnect />
                    </>
                    :
                    <>
                        <div className="group-edit-setting">
                            <div className="row-back-profile">
                                <Link to="/apps/portfolio">
                                    <img src="/images/user/back.png" alt="" /> Account Settings
                                </Link>
                                <div className="desc">
                                    Settings and options for you application
                                </div>
                            </div>
                            {/* <KycPage /> */}
                            <div className="form-group-setting">
                                <div className="colum-group">
                                    <div className="columns">
                                        <div className="colum w-5">
                                            <div className="content-between">
                                                <div className="text-row">
                                                    KYC Status
                                                </div>
                                                <div className="desc-row">
                                                    Update your contact information
                                                </div>
                                            </div>
                                        </div>
                                        <div className="colum w-7">
                                            {dataInfo === undefined ?
                                                <>
                                                    <Skeleton active paragraph={{ rows: 4 }} />
                                                </>
                                                :
                                                <>
                                                    <div className="content-form">
                                                        <div className="group-input" onClick={showModal}>
                                                            <div className="kyc-status-party">
                                                                <div className="box-img">
                                                                    {dataKyc?.status === 2 ? <img src="/images/user/finger-active.png" alt="" /> : <img src="/images/user/finger.png" alt="" />}
                                                                </div>
                                                                <div className="text-finger">
                                                                    {renderStatus(dataKyc?.status)} {dataKyc?.status === 2 ? "" : <span>Start verification</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="colum-group">
                                    <div className="columns">
                                        <div className="colum w-5">
                                            <div className="content-between">
                                                <div className="text-row">
                                                    Profile Image
                                                </div>
                                                <div className="desc-row">
                                                    Change your avatar
                                                </div>
                                            </div>
                                        </div>
                                        <div className="colum w-7">
                                            {dataInfo === undefined ?
                                                <>
                                                    <Skeleton active paragraph={{ rows: 4 }} />
                                                </>
                                                :
                                                <>
                                                    <div className="content-form">
                                                        <div className="guide-avatar">
                                                            <div className="left-av">
                                                                <div>
                                                                    <input
                                                                        type="file"
                                                                        name="images"
                                                                        ref={fileInputRef}
                                                                        onChange={fileSelectedHandler}
                                                                        style={{ display: "none" }}
                                                                    />
                                                                    <div onClick={handleImageClick} style={{ cursor: "pointer" }}>
                                                                        {preview ? (
                                                                            <img
                                                                                src={preview}
                                                                                alt="Avatar Preview"
                                                                                style={{
                                                                                    width: "100px",
                                                                                    height: "100px",
                                                                                    objectFit: "cover",
                                                                                    borderRadius: "50%",
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <div
                                                                                style={{
                                                                                    width: "100px",
                                                                                    height: "100px",
                                                                                    borderRadius: "50%",
                                                                                }}
                                                                            >
                                                                                <img src={dataInfo?.dataAccount.avatar} alt="" />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="right-av">
                                                                {selectedFile === null ?
                                                                    <>
                                                                        <div className="box-img" onClick={handleUploadFiel}>
                                                                            <img src="/images/user/img-upload.png" alt="" />
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className="box-img" onClick={handleUpload}>
                                                                            <img src="/images/user/img-upload.png" alt="" />
                                                                        </div>
                                                                    </>
                                                                }
                                                                <div className="desc-pp">
                                                                    We recommend an image of at least 300x300. Max 5mb.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="colum-group res">
                                    <div className="columns">
                                        <div className="colum w-5">
                                            <div className="content-between">
                                                <div className="text-row">
                                                    Contact Info
                                                </div>
                                                <div className="desc-row">
                                                    Update your contact information
                                                </div>
                                            </div>
                                        </div>
                                        <div className="colum w-7">
                                            {dataInfo === undefined ?
                                                <>
                                                    <Skeleton active paragraph={{ rows: 4 }} />
                                                </>
                                                :
                                                <>
                                                    <div className="content-form">
                                                        <div className="group-input">
                                                            <div className="row-input">
                                                                <div className="txt">
                                                                    First Name
                                                                </div>
                                                                <input value={valueFirstName} onChange={onChangeFirstName} type="text" placeholder="..." className="f-control" />
                                                            </div>
                                                            <div className="row-input">
                                                                <div className="txt">
                                                                    Last Name
                                                                </div>
                                                                <input value={valueLastName} onChange={onChangeLastName} type="text" placeholder="..." className="f-control" />
                                                            </div>
                                                        </div>
                                                        <div className="row-input">
                                                            <div className="txt">
                                                                Phone
                                                            </div>
                                                            <input value={valuePhone} onChange={onChangePhone} type="text" placeholder="..." className="f-control" />
                                                        </div>
                                                        <div className="row-input">
                                                            <div className="txt">
                                                                Email Address
                                                            </div>
                                                            <input value={valueEmail} onChange={onChangeEmail} type="text" placeholder="..." className="f-control" />
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="group-button-save">
                                    {dataInfo === undefined ?
                                        <>
                                            <Skeleton active paragraph={{ rows: 4 }} />
                                        </>
                                        :
                                        <>
                                            {loading ?
                                                <>
                                                    <button className="btn-save-edit dis">
                                                        Save <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                                                    </button>   
                                                </>
                                                :
                                                <>
                                                    <button className="btn-save-edit" onClick={SaveAccountEdit}>
                                                        Save
                                                    </button>
                                                </>
                                            }
                                           
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                }

            </div>
            <Modal className="modal-purchase ress" title="Start verification" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Synaps
                    sessionId={dataKyc && dataKyc?.sessionId}
                    service={"individual"}
                    lang={"en"}
                    onReady={() => console.log("component ready")}
                    onFinish={() => handleFinish()}
                    color={{
                        primary: "212b39",
                        secondary: "000",
                    }}
                    withFinishButton={true}
                />
            </Modal>
        </>
    )
}
export default EditProfile