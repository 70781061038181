import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style.scss"
import { FB_URL, TELE_URL, X_URL, YT_URL } from "../../constants";
import { Tooltip } from "antd";
import { Noti } from '../Noti/toast';

interface TokenProps {
    address: string;
    symbol: string;
    decimals: number;
}

declare global {
    interface Window {
        ethereum: any
    }
}

const FooterApp = () => {
    const naigation = useNavigate()

    const goToTele = () => {
        window.open(`${TELE_URL}`)
    }
    const goToFB = () => {
        window.open(`${FB_URL}`)
    }
    const goToX = () => {
        window.open(`${X_URL}`)
    }
    const goToYT = () => {
        window.open(`${YT_URL}`)
    }

    const handleSetkey = (key: any) => {
        localStorage.setItem("keyActiveMenu", key)
        window.location.reload()
    }

    const addTokenToWallet = async (props: TokenProps) => {
        const { address, symbol, decimals }: any = props;
        const provider: any = (window as Window).ethereum;
        try {
            const wasAdded = await provider.request({
                method: "wallet_watchAsset",
                params: {
                    type: "ERC20",
                    options: {
                        address,
                        symbol,
                        decimals,
                    },
                },
            });
            if (!wasAdded) {
                Noti("success", "Add token success", 3000)
            }
        } catch (error: any) {
            Noti("error", error?.message, 3000)
        }
    };

    return (
        <>
            <footer className="footer-app">
                <div className="row-footer">
                    <div className="left-footer">
                        <div className="box-img">
                            <img src="/images/logo.png" alt="" />
                        </div>
                        <div className="desc-ft">
                            Revolutionizing Real Estate Investment
                        </div>
                        <div className="email-ft">
                            <img src="/images/img-mail.png" alt="" /> <a href="mailto:contact@mey.network">contact@mey.network</a>
                        </div>
                        <div className="groud-info-token">
                            {/* <Tooltip title="Coming soon">
                                <div className="row-info">
                                    <img src="/images/logo-mey.png" alt="" /> $0.005
                                </div>
                            </Tooltip> */}
                            <div className="row-info" onClick={() =>
                                addTokenToWallet({
                                    address: `0x8bFAc1b375bf2894D6F12fb2Eb48B1C1a7916789`,
                                    symbol: `MEY`,
                                    decimals: 18,
                                })
                            }>
                                <img src="/images/metamask.png" alt="" /> Add to wallet
                            </div>
                        </div>
                    </div>
                    <div className="right-footer">
                        <div className="list-menu-ft">
                            <div className="title-name">
                                Products
                            </div>
                            <ul className="menu-ft">
                                <li onClick={() => handleSetkey(2)}>
                                    <Link to={"/apps/staking"}>
                                        Mey Staking
                                    </Link>
                                </li>
                                <li onClick={() => handleSetkey(4)}>
                                    <Link to={"/apps/p2p-lending"}>
                                        P2P Lending
                                    </Link>
                                </li>
                                <li onClick={() => handleSetkey(5)}>
                                    <Link to={"/apps/pto"}>
                                        Prop Token Offering (PTO)
                                    </Link>
                                </li>
                                <li onClick={() => handleSetkey(6)}>
                                    <Link to={"/apps/coming-soon"}>
                                        MeyFi Marketplace
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="list-menu-ft">
                            <div className="title-name">
                                Legal
                            </div>
                            <ul className="menu-ft">
                                <li>
                                    <Link to={"/PrivacyPolicy"}>
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/Term"}>
                                        Term of Use
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/PrivacyPolicy"}>
                                        ALM Policy
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="list-menu-ft">
                            <div className="title-name">
                                Community
                            </div>
                            <ul className="menu-ft">
                                <li>
                                    <a href="#" onClick={goToX}>
                                        <img src="/images/landing/f-x1.png" alt="" /> X
                                    </a>
                                </li>
                                <li>
                                    <a href="#" onClick={goToTele}>
                                        <img src="/images/landing/tele.png" alt="" /> Telegram
                                    </a>
                                </li>
                                <li>
                                    <a href="#" onClick={goToYT}>
                                        <img src="/images/landing/yt.png" alt="" /> Youtube
                                    </a>
                                </li>
                                <li>
                                    <a href="#" onClick={goToFB}>
                                        <img src="/images/landing/fb.png" alt="" /> Facebook
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="list-menu-ft">
                            <div className="title-name">
                                Resource
                            </div>
                            <ul className="menu-ft">
                                <li>
                                    <a href="https://drive.google.com/file/d/1ncSC-3RrtAk7msVHi90YsZ7flEIILyOv/view" target="_blank" rel="noopener noreferrer">
                                        Whitepaper
                                    </a>
                                </li>
                                <li>
                                    <a href="/MEY-Tokenomic.pdf" target="_blank" rel="noopener noreferrer">
                                        Tokenomic
                                    </a>
                                </li>
                                <li>
                                    <Tooltip title="Coming soon">
                                        <a href="#">
                                            Roadmap
                                        </a>
                                    </Tooltip>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default FooterApp