import { instance } from "../apiBase/instance";

export const AppHome = {
    getListP2p(params: any) {
        return instance.post<any>(`/p2p/list` , params);
    },
    getListP2pDetail(code: any , address:any) {
        return instance.get<any>(`/p2p/detail?code=${code}&ownerAddress=${address}` , );
    },
    getP2pYourLoans(params: any) {
        return instance.post<any>(`/p2p/your_loans` , params);
    },
    getP2pProperties(params: any) {
        return instance.post<any>(`/p2p/properties` , params);
    },
    AccountInfo(address: any) {
        return instance.get<any>(`/account/detail?walletAddress=${address}`);
    },
    EditAccountInfo(params: any) {
        return instance.post<any>(`/account/edit` , params);
    },
    postUploadAvatar(formData: any) {
        return instance.post<any>(`/account/upload` , formData)
    },
    postHistoryLend(params: any) {
        return instance.post<any>(`/account/add-history` , params)
    },
    getHistoryLend(params: any) {
        return instance.post<any>(`/account/your-loans` , params)
    },
    postDisClaimer(params: any) {
        return instance.post<any>(`/account/disclaimer` , params)
    },
    getTokenizeAsset(params: any) {
        return instance.post<any>(`/tokenize/your-assets` , params);
    },
    getSettingContract() {
        return instance.get<any>(`/setting/list`);
    },
    getDataChart(address: any , filter: any) {
        return instance.get<any>(`/account/chart?walletAddress=${address}&filterDate=${filter}`);
    },
    getDataMarket(pageSize:any ,pageIndex:any , status:any , minValue:any , maxValue:any , type:any , country:any , statusFilter:any) {
        return instance.get<any>(`/market-place/list?pageSize=${pageSize}&pageIndex=${pageIndex}&status=${status}&country=${country}&type=${type}&tokenPriceRange=${[minValue,maxValue]}&tokenStatus=${statusFilter}`);
    },
    getDataMarketDetail(code:any) {
        return instance.get<any>(`/market-place/detail?code=${code}`);
    },
    getDataKyc(params:any) {
        return instance.post<any>(`/kyc/info` , params);
    },
    getDataKycInit(params:any) {
        return instance.post<any>(`/kyc/init` , params);
    },
    postDataSellMarket(params:any) {
        return instance.post<any>(`/market-place/sell` , params);
    },
    getDataSellMarket(code:any) {
        return instance.get<any>(`/market-place/list-sell?code=${code}`);
    },
    postDataBuyMarket(params:any) {
        return instance.post<any>(`/market-place/buy` , params);
    },
    getDataActivity(code:any , pageSize:any , pageIndex:any , type:any) {
        return instance.get<any>(`/market-place/activity?code=${code}&pageSize=${pageSize}&pageIndex=${pageIndex}&type=${type}`);
    },
    getDataTransaction(code:any , pageSize:any , pageIndex:any , type:any , address:any) {
        return instance.get<any>(`/market-place/transaction?code=${code}&pageSize=${pageSize}&pageIndex=${pageIndex}&type=${type}&ownerAddress=${address}`);
    },
    getDataChartMarket(code:any) {
        return instance.get<any>(`/market-place/chart?code=${code}`);
    },
    postSoldOut(params:any) {
        return instance.post<any>(`/p2p/sold_out` , params);
    },
    postDataCancelMarket(params:any) {
        return instance.post<any>(`/market-place/cancel` , params);
    },
    getVerifyEmail(address:any) {
        return instance.get<any>(`account-wl/detail?walletAddress=${address}`);
    },
    postOtpUser(params:any) {
        return instance.post<any>(`account-wl/send-otp` , params);
    },
    postCheckOtp(params:any) {
        return instance.post<any>(`account-wl/verify-otp` , params);
    },
    postSign(params:any) {
        return instance.post<any>(`account-wl/sign` , params);
    },
}