import React, { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { useHookAccount } from "./storeAccount";
import { BASE_SCAN } from "../../../constants";

const StakingComponent = () => {
    const { address } = useAccount();
    const [state, actions] = useHookAccount()
    const [listHisStake, setListHisStake]: any = useState([])
    const getListHis = async () => {
        try {
            let params = {
                ownerAddress: address,
                pageSize: 15,
                pageIndex: 0
            }
            await actions.getHistoryStake(params).then((res: any) => {
                setListHisStake(res)
            })
                .catch((err: any) => {
                    console.log('err', err)
                    setListHisStake([])
                })
        } catch (error) {
            console.log('error', error)
            setListHisStake([])
        }
    }

    useEffect(() => {
        if (address) {
            getListHis()
        }
    }, [address])


    function gotoHash(txHash: any): void {
        window.open(`${BASE_SCAN}/tx/${txHash}`)
    }

    return (
        <>
            <div className="main-table-row row-mb">
                {listHisStake.length > 0 ? (
                    <table cellSpacing="0" className="main-table">
                        <tr>
                            <th>
                                Pool
                            </th>
                            <th>
                                APY %
                            </th>
                            <th>
                                Staked
                            </th>
                            <th>
                                Last Staked Date
                            </th>
                            <th>
                                TxID
                            </th>
                        </tr>
                        <>
                            {listHisStake.map((item: any, index: any) => (
                                <tr>
                                    <td>
                                        <div className="name-pro">
                                            <img src={item?.earLogo} alt="" /> {item?.pool}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="color-apy">
                                            {item?.apy}%
                                        </div>
                                    </td>
                                    <td>
                                        {item?.staked} <span className="main-color-table">{item?.symbol}</span>
                                    </td>
                                    <td>
                                        {item?.stakeDate}
                                    </td>
                                    <td>
                                        <div className="row-link" onClick={() => gotoHash(item?.txHash)}>
                                            {item?.txHash.slice(0, 5)}...{item?.txHash.slice(-5)} <img src="/images/user/link.png" alt="" />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </>


                    </table>
                ) : (
                    <div className="box-no-data-stake">
                        <img className='img-empty' src="/images/no-data-cus.png" alt="" />
                    </div>
                )}

            </div>
        </>
    )
}
export default StakingComponent