import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Layout, Modal } from "antd";
import Header from "../common/header";
import SideBar from "../sidebar";
import "./style.scss";
import HeaderApp from "../header-app";
import FooterApp from "../footer-app";
import ToastNoti from "../Noti";
import { isMobile } from "react-device-detect";

const { Content } = Layout;

function Main({ children }: any) {
    const location = useLocation()
    let pathUrl = location?.pathname.split("/")
    const [dataFromSidebar, setDataFromSidebar] = useState()

    const callbackFunction = (childData: any) => {
        setDataFromSidebar(childData)
    }

    return (
        <Layout>

            {pathUrl[1] === "apps" ? (
                <>
                    {isMobile ?
                        <>
                            <div className="row-layout mobile-layout">
                                <div
                                    className={
                                        dataFromSidebar
                                            ? "flex-layout flex-layout-collapse"
                                            : "flex-layout"
                                    }
                                >
                                    <div className="right">
                                        <HeaderApp />
                                        <Content>{children}</Content>
                                        <FooterApp />
                                    </div>
                                </div>
                                <ToastNoti />
                            </div>
                        </>
                        :
                        <>
                            <div className="row-layout">
                                <div
                                    className={
                                        dataFromSidebar
                                            ? "flex-layout flex-layout-collapse"
                                            : "flex-layout"
                                    }
                                >
                                    <div className="left">
                                        <SideBar callbackFunction={callbackFunction} />
                                    </div>
                                    <div className="right">
                                        <HeaderApp />
                                        <Content>{children}</Content>
                                        <FooterApp />
                                    </div>
                                </div>
                                <ToastNoti />
                            </div>
                        </>
                    }
                </>
            ) : (
                <>
                    <Header />
                    <Content>{children}</Content>
                    <div className="container">
                        <FooterApp />
                    </div>
                </>
            )}

        </Layout>
    );
}

export default Main;
