
export const rendererCountDown = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
}) => {
    // Render a countdown
    if (completed) {
        return <span>Withdrawable Now</span>
    }
    if (days > 1) {
        return (
            <span>
                {days}{" "}days : {hours}{" "}h : {minutes}{" "}m : {seconds}{" "}s
            </span>
        );
    } else if (days === 1) {
        return (
            <span>
                {days}{" "}day : {hours}{" "}h : {minutes}{" "}m : {seconds}{" "}s
            </span>
        );
    } else {
        return (
            <span>
                {hours}{" "}h : {minutes}{" "}m : {seconds}{" "}s
            </span>
        );
    }
};
export const rendererCountDownList = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
}) => {
    // Render a countdown
    if (completed) {
        return <span>Finished</span>
    }
    if (days > 1) {
        return (
            <span className="boc-time">
                <span className="tec-tem">{days}<span className="tec-time">D</span></span>
                {" "}:{" "}
                <span className="tec-tem">{hours}<span className="tec-time">H</span></span>
                {" "}:{" "}
                <span className="tec-tem">{minutes}<span className="tec-time">M</span></span>
                {" "}:{" "}
                <span className="tec-tem">{seconds}<span className="tec-time">S</span></span>
            </span>
        );
    } else if (days === 1) {
        return (
            <span className="boc-time">
                <span className="tec-tem">{days}<span className="tec-time">D</span></span>
                {" "}:{" "}
                <span className="tec-tem">{hours}<span className="tec-time">H</span></span>
                {" "}:{" "}
                <span className="tec-tem">{minutes}<span className="tec-time">M</span></span>
                {" "}:{" "}
                <span className="tec-tem">{seconds}<span className="tec-time">S</span></span>
            </span>
        );
    } else {
        return (
            <span className="boc-time">
                <span className="tec-tem">{hours}<span className="tec-time">H</span></span>
                {" "}:{" "}
                <span className="tec-tem">{minutes}<span className="tec-time">M</span></span>
                {" "}:{" "}
                <span className="tec-tem">{seconds}<span className="tec-time">S</span></span>
            </span>
        );
    }
};

export const rendererCountDown1 = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
}) => {
    // Render a countdown
    if (completed) {
        return ''
    }
    if (days > 1) {
        return (
            <span>
                {days}days : {hours}h : {minutes}m : {seconds}s
            </span>
        );
    } else if (days === 1) {
        return (
            <span>
                {days}day : {hours}h : {minutes}m : {seconds}s
            </span>
        );
    } else {
        return (
            <span>
                {hours}h : {minutes}m : {seconds}s
            </span>
        );
    }
};

export const rendererCountDown2 = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
}) => {
    // Render a countdown
    if (completed) {
        return <span>Unstakeable Now</span>
    }
    if (days > 1) {
        return (
            <span>
                {days}{" "}days : {hours}{" "}h : {minutes}{" "}m : {seconds}{" "}s
            </span>
        );
    } else if (days === 1) {
        return (
            <span>
                {days}{" "}day : {hours}{" "}h : {minutes}{" "}m : {seconds}{" "}s
            </span>
        );
    } else {
        return (
            <span>
                {hours}{" "}h : {minutes}{" "}m : {seconds}{" "}s
            </span>
        );
    }
};



