export const SC_VESTING = "0xfE1Dc1b240F14E426cb2d2Caa43CDe4CD3040F98"
export const TOKEN_PTO = "0x4ca14968dC36208EdfB1F7d434E849f9a240b7F0"
export const SC_CLAIM_REF = "0x0C4f64015E4AadDc152ee56ECFCFa19f9AD20298"

export const API_URL = process.env.REACT_APP_API_ENDPOINT
export const DECIMAL = 18
export const NUM_FORMAT = 4

// config TESTNET
// export const RPC_BASE = "https://sepolia.base.org"
// export const BASE_SCAN = "https://sepolia.basescan.org"
// export cont WORK_sCHAIN = 84532

// config MAINNET
export const RPC_BASE = "https://proud-silent-sky.base-mainnet.quiknode.pro/ff3f057f7dec2c8b28e9c15442aa037893b40969/"
export const BASE_SCAN = "https://basescan.org"
export const WORK_CHAIN = 8453


export const TELE_URL = "https://t.me/Mey_Network"
export const FB_URL = "https://www.facebook.com/MeyNetwork"
export const X_URL = "https://x.com/Mey_Network"
export const YT_URL = "https://www.youtube.com/@MeyNetwork"





